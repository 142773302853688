// Se importan los elementos necesarios para el correcto funcionamiento.
import { defineStore } from "pinia";
import {
  getReporteClientesSupervisor,
  getReporteClientesGeneral,
} from "../api/clientes.js";
import { useSupStore } from "./supStore.js";
import useUserStore from "./userStore.js";

// Se crea el almacen de clientes.
export const useClientStore = defineStore("client", {
  state: () => ({
    // Se inicializan las variables.
    selectedClient: null,
    clients: [],
  }),

  actions: {
    // Se crean las acciones para cargar los clientes y seleccionar un cliente.
    async loadClients() {
      const supStore = useSupStore();
      const userStore = useUserStore();
      const user = userStore.user;

      // Obtener el rol y el id del usuario.
      const userRole = user?.rol;
      const userId = user?.id;

      try {
        let clientsData = [];

        const fecha = new Date().toISOString().split("T")[0];

        // Si el rol es admin, utilizar el ID del supervisor seleccionado.
        if (userRole === "admin") {
          if (!supStore.selectedSup || !supStore.selectedSup.unikey_SU) {
            this.clients = [];
            return;
          }
          const Sup_Cod_Cartera = supStore.selectedSup.unikey_SU;

          if (Sup_Cod_Cartera === 100) {
            const response_Clientes_General = await getReporteClientesGeneral(
              fecha,
              Sup_Cod_Cartera
            );
            const reporteClientesGeneral =
              response_Clientes_General.dataclientesgeneral;

            if (
              reporteClientesGeneral &&
              Array.isArray(reporteClientesGeneral.dataclientesgeneral)
            ) {
              clientsData = reporteClientesGeneral.dataclientesgeneral.map(
                (cliente) => ({
                  unikey_CL: cliente.Unikey,
                  name_CL: cliente.NomComp,
                })
              );
            }
          } else {
            // Clientes específicos del supervisor.
            const response_Clientes_Supervisor =
              await getReporteClientesSupervisor(fecha, Sup_Cod_Cartera);

            const reporteClientesSupervisor =
              response_Clientes_Supervisor.dataclientessupervisor;

            if (
              reporteClientesSupervisor &&
              Array.isArray(reporteClientesSupervisor.dataclientessupervisor)
            ) {
              clientsData =
                reporteClientesSupervisor.dataclientessupervisor.map(
                  (cliente) => ({
                    unikey_CL: cliente.Unikey,
                    name_CL: cliente.NomComp,
                  })
                );
            } else {
              console.error(
                "La estructura de los datos de clientes del supervisor es incorrecta",
                reporteClientesSupervisor
              );
            }
          }
        } else if (userRole === "manager") {
          // Si el rol es manager, usar el ID del usuario (ID del manager)
          if (!userId) {
            console.error("No se encontró el ID del manager.");
            return;
          }

          const response_Clientes_Supervisor =
            await getReporteClientesSupervisor(fecha, userId);

          const reporteClientesSupervisor =
            response_Clientes_Supervisor.dataclientessupervisor;

          if (
            reporteClientesSupervisor &&
            Array.isArray(reporteClientesSupervisor.dataclientessupervisor)
          ) {
            clientsData = reporteClientesSupervisor.dataclientessupervisor.map(
              (cliente) => ({
                unikey_CL: cliente.Unikey,
                name_CL: cliente.NomComp,
              })
            );
          } else {
            console.error(
              "La estructura de los datos de clientes del supervisor es incorrecta",
              reporteClientesSupervisor
            );
          }
        }

        // Se llena la lista de clientes con los datos obtenidos.
        if (Array.isArray(clientsData)) {
          this.clients = clientsData;
        }

        // Agregar cliente (GENERAL) al inicio de la lista.
        this.clients.unshift({
          name_CL: "GENERAL",
          unikey_CL: 100,
        });
      } catch (error) {
        console.error("Error al cargar clientes:", error);
        this.clients = [];
      }
    },

    // Se establece el cliente seleccionado.
    setSelectedClient(client) {
      this.selectedClient = client;
    },

    // Se limpia el cliente seleccionado.
    clearSelectedClient() {
      this.selectedClient = null;
    },
  },
});
