<template>
  <!-- Se define el botón. -->
  <button class="icon-button" @click="handleClick">
    <!-- Icono del botón. -->
    <i v-if="icon" :class="`fa ${icon}`" class="icon-button__icon"></i>
    <!-- El segundo parámetro y tercero son los textos que mostrará el botón. -->
    <div class="icon-button__text">
      <span class="icon-button__text--primary">{{ primaryText }}</span>
      <span class="icon-button__text--secondary">{{ secondaryText }}</span>
    </div>
  </button>
</template>

<script>
// Se importan los elementos necesarios para el correcto funcionamiento del componente.
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "IconButton",
  props: {
    // Se definen los props del componente, todos los necesarios con sus tipos permitidos y si son requeridos o no.
    icon: {
      type: String,
      required: false,
    },
    primaryText: {
      type: String,
      required: false,
    },
    secondaryText: {
      type: String,
      required: false,
    },
    to: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    // Se importa el router para poder navegar entre las distintas rutas de la aplicación.
    const router = useRouter();

    // Función que permite navegar entre las distintas rutas de la aplicación al presionar cada botón.
    const navigate = () => {
      if (!props.disabled) {
        router.push(props.to);
      }
    };

    // Función que maneja el evento del clic en el botón.
    const handleClick = () => {
      if (!props.disabled) {
        emit("click");
        navigate();
      }
    };

    // Se retorna la función handleClick para ser utilizada en el template.
    return {
      handleClick,
    };
  },
});
</script>

<!-- Se importa el archivo css correspondiente al compontente. -->
<style scoped>
@import "../css/boton.css";
</style>
