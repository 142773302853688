<template>
  <!-- Se definen los contenedores para el componente sidemenu (rectangulo verde con nombre y foto del supervisor). -->
  <div class="sidebar-menu">
    <div class="profile-section">
      <div class="profile-image">
        <!-- En ese apartado se debe entregar la imagen del supervisor, por el momento queda foto de usuario estandar. -->
        <img :src="userIcon" class="sidemenu-icon" />
      </div>
      <!-- Se muestra el nombre del supervisor seleccionado. -->
      <p class="supervisor-name">
        {{
          capitalizeFirstLetter(getFirstName(user?.nombres)) ||
          "Sin supervisor seleccionado"
        }}
        {{ capitalizeFirstLetter(getFirstLastName(user?.apellidos)) || "" }}
      </p>
    </div>
  </div>
</template>

<script>
// Se importan los elementos necesarios para el correcto funcionamiento del componente.
import { computed } from "vue";
// Se importa la tienda de supervisores, para poder visualizar el nombre del supervisor seleccionado.
import { useSupStore } from "../stores/supStore.js";
import useUserStore from "../stores/userStore.js";
import usuario from "../assets/images/usuario.svg";

export default {
  name: "SideMenu",
  data() {
    return {
      userIcon: usuario,
    };
  },
  setup() {
    // Se definen lo necesario para obtener el supervisor seleccionado (uso de la tienda y el elemento computed).
    const supStore = useSupStore();
    const selectedSup = computed(() => supStore.selectedSup);
    const userStore = useUserStore();
    const user = userStore.user;

    // Función para obtener el primer nombre
    const getFirstName = (fullName) => {
      return fullName ? fullName.split(" ")[0] : "";
    };

    // Función para obtener el primer apellido
    const getFirstLastName = (lastName) => {
      return lastName ? lastName.split(" ")[0] : "";
    };

    function capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    return {
      // Se retorna el supervisor seleccionado.
      selectedSup,
      user,
      getFirstName,
      getFirstLastName,
      capitalizeFirstLetter,
    };
  },
};
</script>

<!-- Se importa el archivo css correspondiente al compontente. -->
<style scoped>
@import "../css/sidemenu.css";
</style>
