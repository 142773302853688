<template>
  <div class="margen_total">
    <div v-if="!dataLoaded" class="loading-spinner">
      <i class="fa-solid fa-spinner fa-spin"></i>
      <div class="spinner"></div>
    </div>
    <!-- Se utiliza el componente Tabla para mostrar la información de los CPA pendientes históricos asociados al cliente. -->
    <!-- Se utiliza un condicional para que se muestre la información en caso de que existan datos. -->
    <!-- Posteriormente se define y se entregan todos los parámetros necesarios para que la tabla se visualice correctamente. -->
    <Tabla
      v-if="dataLoaded && cpaInfo.length > 0"
      :Info="cpaInfo"
      :subnum1="cpapendientes"
      :subnum2="cpapendientes3meses"
      :enablePopup="false"
      :columnHeaders="[
        'Código CPA',
        'PPU',
        'CPA',
        'Cargos',
        'Fecha Apertura',
        'Días Aperturado',
      ]"
      :rowFields="[
        { key: 'CODIGO_CPA', format: 'number' },
        { key: 'PPU', format: 'text' },
        { key: 'CPA', format: 'currency' },
        { key: 'Cargos', format: 'currency' },
        { key: 'FECHA_APERTURA', format: 'date' },
        { key: 'DIAS_APERTURADO', format: 'number' },
      ]"
      :enableFilters="[true, true, true, true, true, true]"
      tableTitle="CPA pendientes Histórico"
      subtable1="# CPA pendientes"
      subtable2="# CPA sobre 3 meses"
      numColspan="6"
      subnumColspan="3"
      dataColspan="3"
      :disabled="false"
    />
    <!-- Mensaje en caso de que no existan CPA pendientes históricos asociados al cliente. -->
    <p v-if="dataLoaded && cpaInfo.length === 0">
      El cliente {{ selectedClient.name_CL }} no presenta CPA pendientes
      históricos en la fecha seleccionada.
    </p>
  </div>
</template>

<script>
// Se importan las librerías y archivos necesarios.
import { ref, computed, onMounted } from "vue";
import Tabla from "../components/Tabla_botones.vue";
import { useClientStore } from "../stores/clientStore.js";
import { useSupStore } from "../stores/supStore.js";
import useUserStore from "../stores/userStore.js";
import { useDateStore } from "../stores/dateStore.js";
import { getReporteCPA } from "../api/cpa.js";

export default {
  components: {
    Tabla,
  },
  setup() {
    // Se definen las variables reactivas y los stores necesarios.
    const cpaInfo = ref([]);
    const cpapendientes = ref(null);
    const cpapendientes3meses = ref(null);
    const dataLoaded = ref(false);

    // Se accede a los stores.
    const clientStore = useClientStore();
    const supStore = useSupStore();
    const userStore = useUserStore();
    const dateStore = useDateStore();

    // Se obtienen los valores seleccionados en los stores.
    const selectedClient = computed(() => clientStore.selectedClient);
    const selectedSup = computed(() => supStore.selectedSup);
    const selectedDate = ref(dateStore.selectedDate || null);

    // Se define la función loadData para obtener la información de los CPA pendientes históricos asociados al cliente.
    const loadData = async () => {
      try {
        // Se definen las variables necesarias.
        const userRole = userStore.user?.role;
        const userId = userStore.user?.id;

        // Se establece el valor de Sup_Cod_Cartera según el rol del usuario.
        let Sup_Cod_Cartera = null;
        if (userRole === "manager") {
          Sup_Cod_Cartera = userId;
        } else if (selectedSup.value && selectedSup.value.unikey_SU) {
          Sup_Cod_Cartera = selectedSup.value.unikey_SU;
        }

        // Se establece el valor de Numero_Cliente según el cliente seleccionado.
        const Numero_Cliente = selectedClient.value.unikey_CL;

        // Se define el caso según los valores de Sup_Cod_Cartera y Numero_Cliente.
        let caso;
        if (Sup_Cod_Cartera === 100 && Numero_Cliente === 100) {
          caso = "General-General";
        } else if (Sup_Cod_Cartera !== 100 && Numero_Cliente === 100) {
          caso = "General-Supervisor";
        } else if (Sup_Cod_Cartera === 100 && Numero_Cliente !== 100) {
          caso = "General-Cliente";
        } else {
          caso = "Supervisor-Cliente";
        }

        // Se formatea la fecha seleccionada.
        const fecha = new Date(selectedDate.value).toISOString().split("T")[0];

        // Se definen las variables SupId y ClienteId.
        const SupId = Sup_Cod_Cartera;
        const ClienteId = selectedClient.value.unikey_CL;

        // Se realiza la consulta a la API para obtener los datos de los CPA pendientes del cliente seleccionado.
        const response = await getReporteCPA(fecha, caso, SupId, ClienteId);

        // Se asignan los valores obtenidos a las variables correspondientes.
        const reporteCPA = response?.datareportecpa;
        cpapendientes.value = reporteCPA?.CPA_PENDIENTES || 0;
        cpapendientes3meses.value = reporteCPA?.CPA_PENDIENTES_3_MESES || 0;

        // Si la el segundo dataset (datacpa) es un array va llenando la tabla con la información.
        if (reporteCPA && Array.isArray(reporteCPA.datacpa)) {
          cpaInfo.value = reporteCPA.datacpa.map((item) => ({
            ...item,
            CPA: formatCurrency(item["CPA"]),
            Cargos: formatCurrency(item["Cargos"]),
            FECHA_APERTURA: formatDate(item.FECHA_APERTURA),
            DIAS_APERTURADO: formatNumber(item["DIAS_APERTURADO"]),
          }));
        }

        // Se establece el valor de dataLoaded en true.
        dataLoaded.value = true;
      } catch (error) {
        console.error("Error al obtener la información:", error);
        dataLoaded.value = true;
      }
    };

    // Se definen las funciones de formateo de datos.
    // Función para formatear números.
    const formatNumber = (value) => {
      if (value === "-" || value === 0 || value === "0" || value === "N/A") {
        return "0";
      }
      if (value === "OT Abierta") {
        return value;
      }
      return new Intl.NumberFormat().format(value);
    };

    // Función para formatear fechas.
    const formatDate = (value) => {
      if (value === "Pendiente" || !value) {
        return value || "N/A";
      }
      return new Date(value).toLocaleDateString("es-CL");
    };

    // Función para formatear dinero.
    const formatCurrency = (value) => {
      if (value === "Sin costo asociado" || value === "Sin cargo asociado") {
        return value;
      }
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }).format(value);
    };

    // Se llama a la función loadData al montar el componente.
    onMounted(loadData);

    // Se retornan las variables y funciones necesarias para el componente.
    return {
      cpaInfo,
      cpapendientes,
      cpapendientes3meses,
      dataLoaded,
      selectedClient,
      selectedSup,
    };
  },
};
</script>

<!-- css para el ícono de carga. -->
<style scoped>
@import "../css/spinner.css";
</style>
