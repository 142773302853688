<template>
  <div class="menu-wrapper">
    <ul class="menu-items">
      <li>
        <!-- Se establece el botón Home, el cual al ser presionado redirige a la ruta Home. -->
        <button
          class="menu-button"
          :class="{ active: isActive('Home') }"
          @click="handleClick('Home')"
        >
          <!-- Se establece el icono del botón. -->
          <img :src="homeIcon" class="menu-icon" />
          <!-- <i class="fa-solid fa-house menu-icon"></i> -->
          <span class="button-text">Home</span>
        </button>
      </li>
      <li>
        <!-- Se establece el botón Historico, el cual al ser presionado redirige a la ruta Home. -->
        <button
          class="menu-button"
          :class="{ active: isActive('Historic') }"
          @click="handleClick('Historic')"
        >
          <!-- Se establece el icono del botón. -->
          <img :src="calendarIcon" alt="Histórico Icon" class="menu-icon" />
          <!-- <i class="fa-solid fa-calendar-days menu-icon"></i> -->
          <span class="button-text">Histórico</span>
        </button>
      </li>
      <li>
        <!-- Se establece el botón Clientes, el cual al ser presionado redirige a la
        ruta Home. -->
        <button
          class="menu-button"
          :class="{ active: isActive('Clientes') }"
          @click="handleClick('Clientes')"
        >
          <!-- Se establece el icono del botón. -->
          <img :src="usersIcon" alt="Histórico Icon" class="menu-icon" />
          <!-- <i class="fa-solid fa-calendar-days menu-icon"></i> -->
          <span class="button-text">Clientes</span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
// Importar los elementos necesarios para el correcto funcionamiento del componente.
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import home from "../assets/images/home.svg";
import calendar from "../assets/images/calendario.svg";
import users from "../assets/images/users.svg";

export default defineComponent({
  name: "MenuButtons",
  data() {
    return {
      homeIcon: home,
      calendarIcon: calendar,
      usersIcon: users,
    };
  },
  setup() {
    // Se importa el router para poder navegar entre las distintas rutas de la aplicación.
    const router = useRouter();

    // Función para verificar si una ruta está activa.
    const isActive = (routeName) => {
      return router.currentRoute.value.name === routeName;
    };

    // Función para manejar el clic en el botón.
    const handleClick = (routeName) => {
      router.push({ name: routeName });
    };

    // Se retorna la función handleClick para ser utilizada en el template.
    return {
      isActive,
      handleClick,
    };
  },
});
</script>

<!-- Se importa el archivo css correspondiente al compontente. -->
<style scoped>
@import "../css/menu.css";
</style>
