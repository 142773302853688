// Se importan todos los elementos necesarios para el correcto funcionamiento.
import { defineStore } from "pinia";
import { ref } from "vue";

// Se crea el almacen de fechas.
export const useDateStore = defineStore("date", () => {
  const selectedDate = ref(null);

  // Función para actualizar la fecha seleccionada.
  const setSelectedDate = (date) => {
    selectedDate.value = date;
  };

  // Se retornan las variables y funciones necesarias.
  return { selectedDate, setSelectedDate };
});
