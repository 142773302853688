// Se importa lo necesario para la correcta llamada a la base de datos (se importa api).
import { api } from "../services/api.js";

// Función para obtener la métrica CPA Supervisor (detalle, numérica y porcentual).
export async function getReporteMetricaSupervisorCPA(
  fecha,
  metrica,
  id_supervisor
) {
  try {
    const response = await api.get(
      `/reportes/metrica_cpa_supervisor?fecha='${fecha}'&metrica=${metrica}&id_supervisor=${id_supervisor}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener la métrica CPA:", error);
    throw error;
  }
}

// Función para obtener la métrica Mantenciones4D Supervisor (detalle, numérica y porcentual).
export async function getReporteMetricaSupervisorMantenciones4D(
  fecha,
  metrica,
  id_supervisor
) {
  try {
    const response = await api.get(
      `/reportes/metrica_mantenciones4d_supervisor?fecha='${fecha}'&metrica=${metrica}&id_supervisor=${id_supervisor}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener el reporte Mantenciones4D:", error);
    throw error;
  }
}

// Función para obtener la métrica MantencionesEST Supervisor (detalle, numérica y porcentual).
export async function getReporteMetricaSupervisorMantencionesEST(
  fecha,
  metrica,
  id_supervisor
) {
  try {
    const response = await api.get(
      `/reportes/metrica_mantencionesest_supervisor?fecha='${fecha}'&metrica=${metrica}&id_supervisor=${id_supervisor}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener el reporte MantencionesEST:", error);
    throw error;
  }
}

// Función para obtener la métrica RevisionesTecnicas Supervisor (detalle, numérica y porcentual).
export async function getReporteMetricaSupervisorRevisionesTecnicas(
  fecha,
  metrica,
  id_supervisor
) {
  try {
    const response = await api.get(
      `/reportes/metrica_revisionestecnicas_supervisor?fecha='${fecha}'&metrica=${metrica}&id_supervisor=${id_supervisor}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener el reporte RevisionesTecnicas:", error);
    throw error;
  }
}

// Función para obtener la métrica RevisionesGases Supervisor (detalle, numérica y porcentual).
export async function getReporteMetricaSupervisorRevisionesGases(
  fecha,
  metrica,
  id_supervisor
) {
  try {
    const response = await api.get(
      `/reportes/metrica_revisionesgases_supervisor?fecha='${fecha}'&metrica=${metrica}&id_supervisor=${id_supervisor}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener el reporte RevisionesGases:", error);
    throw error;
  }
}

// Función para obtener la métrica Reemplazos Supervisor (detalle, numérica y promedio).
export async function getReporteMetricaSupervisorReemplazos(
  fecha,
  metrica,
  id_supervisor
) {
  try {
    const response = await api.get(
      `/reportes/metrica_reemplazos_supervisor?fecha='${fecha}'&metrica=${metrica}&id_supervisor=${id_supervisor}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener el reporte Reemplazos:", error);
    throw error;
  }
}

// Función para obtener la métrica Unidades Nuevas Supervisor (detalle, numérica y promedio).
export async function getReporteMetricaSupervisorUnidadesNuevas(
  fecha,
  metrica,
  id_supervisor
) {
  try {
    const response = await api.get(
      `/reportes/metrica_unidadesnuevas_supervisor?fecha='${fecha}'&metrica=${metrica}&id_supervisor=${id_supervisor}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener la métrica Unidades Nuevas:", error);
    throw error;
  }
}

// Función para obtener la métrica Unidades Nuevas Supervisor (detalle, numérica y porcentual).
export async function getReporteMetricaSupervisorProximosVencimientos(
  fecha,
  metrica,
  id_supervisor
) {
  try {
    const response = await api.get(
      `/reportes/metrica_proximosvencimientos_supervisor?fecha='${fecha}'&metrica=${metrica}&id_supervisor=${id_supervisor}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener la métrica Próximos Vencimientos:", error);
    throw error;
  }
}
