<template>
  <div class="margen_total">
    <div v-if="!dataLoaded" class="loading-spinner">
      <i class="fa-solid fa-spinner fa-spin"></i>
      <div class="spinner"></div>
    </div>
    <!-- Se utiliza el componente Tabla para mostrar la información respecto a los reemplazos activos que presenta un cliente. -->
    <!-- Posteriormente se define y se entregan todos los parámetros necesarios para que la tabla se visualice correctamente. -->
    <div class="table-container">
      <Tabla
        v-if="dataLoaded && reemplazosInfo.length > 0"
        :Info="reemplazosInfo"
        :subnum1="reemplazosnum"
        :subnum2="reemplazosentrega"
        :columnHeaders="[
          'PPU Reemplazo',
          'Código Reemplazo',
          'PPU Titular',
          'Código Titular',
          'Status Titular',
          'Región',
          'Fecha Reemplazo',
          'Días Reemplazo Abierto',
          'Días Cierre OT',
        ]"
        :rowFields="[
          { key: 'PPU_REEMPLAZO', format: 'text' },
          { key: 'CODIGO_REEMPLAZO', format: 'text' },
          { key: 'PPU_ORIGINAL', format: 'text' },
          { key: 'CODIGO_ORIGINAL', format: 'text' },
          { key: 'STATUS_ORIGINAL', format: 'number' },
          { key: 'REGION', format: 'text' },
          { key: 'FECHA_REEMPLAZO', format: 'date' },
          { key: 'DIAS_REEMPLAZO', format: 'number' },
          { key: 'DIAS_CIERRE_REMP', format: 'number' },
        ]"
        tableTitle="UNIDADES REEMPLAZO"
        subtable1="# UNIDADES REEMPLAZO ACTIVO"
        subtable2="# UNIDADES DISPONIBLES PARA ENTREGA"
        :numColspan="9"
        :subnumColspan="5"
        :dataColspan="5"
        :enablePopup="false"
        :enableFilters="[true, true, true, true, true, true, true, true, true]"
        :disabled="false"
      />
      <!-- En caso de que no existan datos, la vista mostrará al usuario que dicho cliente no presenta reemplazos activos. -->
      <p v-else-if="dataLoaded && reemplazosInfo.length === 0">
        El cliente {{ selectedClient.name_CL }} no presenta un registro
        histórico de los reemplazos activos en la fecha seleccionada.
      </p>
    </div>

    <!-- Se utiliza el componente Tabla para mostrar la información de unidades fuera de servicio. -->
    <div class="table-container">
      <Tabla
        v-if="dataLoaded && fueraservicioInfo.length > 0"
        :Info="fueraservicioInfo"
        :subnum1="fueraservicionum"
        :subnum2="fueraservicioporcentaje + '%'"
        :columnHeaders="[
          'PPU',
          'Codigo',
          'Reemplazo',
          'Ubicación',
          'Taller',
          'Fecha FS',
          'Días FS',
          'Fecha Entrega',
        ]"
        :rowFields="[
          { key: 'PPU', format: 'text' },
          { key: 'CODIGO', format: 'text' },
          { key: 'Reemplazo', format: 'text' },
          { key: 'UBICACION', format: 'text' },
          { key: 'TALLER', format: 'text' },
          { key: 'FECHA_FS', format: 'date' },
          { key: 'DIAS_FS', format: 'number' },
          { key: 'FECHA_ENTREGA', format: 'date' },
        ]"
        tableTitle="UNIDADES FUERA DE SERVICIO"
        subtable1="# UNIDADES FUERA DE SERVICIO"
        subtable2="% FLOTA FUERA DE SERVICIO"
        :numColspan="8"
        :subnumColspan="4"
        :dataColspan="4"
        :enablePopup="false"
        :enableFilters="[true, true, true, true, true, true, true, true]"
        :disabled="false"
      />
      <!-- En caso de que no existan datos, la vista mostrará al usuario que dicho cliente no presenta unidades fuera deservicio. -->
      <p v-else-if="dataLoaded && fueraservicioInfo.length === 0">
        El cliente {{ selectedClient.name_CL }} no presenta un registro
        histórico de las unidades fuera de servicio en la fecha seleccionada.
      </p>
    </div>
  </div>
</template>

<script>
// Se importan los elementos necesarios para el correcto funcionamiento de la vista.
import { ref, computed, onMounted } from "vue";
import Tabla from "../components/Tabla_botones.vue";
import { useClientStore } from "../stores/clientStore.js";
import { useSupStore } from "../stores/supStore.js";
import useUserStore from "../stores/userStore.js";
import { useDateStore } from "../stores/dateStore.js";
import { getReporteReemplazos } from "../api/reemplazos.js";
import { getReporteFueraServicio } from "../api/fueraservicio.js";

export default {
  // Se define el componente Tabla que se utilizará para mostrar la información de las unidades.
  components: {
    Tabla,
  },
  setup() {
    // Se crean las listas que almacenarán toda la información obtenida desde IndexedDB.
    const clientStore = useClientStore();
    const supStore = useSupStore();
    const userStore = useUserStore();
    const dateStore = useDateStore();

    // Se crean las variables selectedSup y selectedClient que permitirán obtener el supervisor y el cliente seleccionado.
    const selectedClient = computed(() => clientStore.selectedClient);
    const selectedSup = computed(() => supStore.selectedSup);
    const selectedDate = ref(dateStore.selectedDate || null);

    // Constantes para el número y porcentaje de reemplazos y fuera de servicio.
    const reemplazosInfo = ref([]);
    const fueraservicioInfo = ref([]);
    const reemplazosnum = ref(null);
    const reemplazosentrega = ref(null);
    const fueraservicionum = ref(null);
    const fueraservicioporcentaje = ref(null);

    // Se inicializa en false la variable que indicará si los datos ya fueron cargados.
    const dataLoaded = ref(false);

    // Función para cargar los datos almacenados en IndexedDB.
    const loadData = async () => {
      try {
        // Se utiliza la tienda de usuarios para obtener el rol y el id del usuario.
        const userRole = userStore.user?.role;
        const userId = userStore.user?.id;

        let Sup_Cod_Cartera = null;

        // Caso en que el usuario tiene rol de manager (el id debe ser el propio del usuario).
        if (userRole === "manager") {
          Sup_Cod_Cartera = userId;
        } else if (selectedSup.value && selectedSup.value.unikey_SU) {
          Sup_Cod_Cartera = selectedSup.value.unikey_SU;
        }

        // Se establece el número de cliente según el cliente seleccionado.
        const Numero_Cliente = selectedClient.value.unikey_CL;

        // Se establece el caso según el supervisor y el cliente seleccionado.
        let caso;
        if (Sup_Cod_Cartera === 100 && Numero_Cliente === 100) {
          caso = "General-General";
        } else if (Sup_Cod_Cartera !== 100 && Numero_Cliente === 100) {
          caso = "General-Supervisor";
        } else if (Sup_Cod_Cartera === 100 && Numero_Cliente !== 100) {
          caso = "General-Cliente";
        } else {
          caso = "Supervisor-Cliente";
        }

        // Se formatea la fecha seleccionada.
        const fecha = new Date(selectedDate.value).toISOString().split("T")[0];

        // Se establecen los valores de los códigos de supervisor y cliente.
        const SupId = Sup_Cod_Cartera;
        const ClienteId = selectedClient.value.unikey_CL;

        // Se obtiene la información de los reemplazos activos.
        const responsereemplazos = await getReporteReemplazos(
          fecha,
          caso,
          SupId,
          ClienteId
        );

        // Se asignan los valores obtenidos a las variables correspondientes.
        const reporteReemplazos = responsereemplazos.datareportereemplazos;
        reemplazosnum.value =
          reporteReemplazos?.UNIDADES_REEMPLAZO_ACTIVAS || 0;
        reemplazosentrega.value =
          reporteReemplazos?.UNIDADES_DISPONIBLES_ENTREGA || 0;

        // Si la el segundo dataset es un array va llenando la tabla con la información.
        if (
          reporteReemplazos &&
          Array.isArray(reporteReemplazos.datareemplazos)
        ) {
          reemplazosInfo.value = reporteReemplazos.datareemplazos.map(
            (item) => ({
              ...item,
              STATUS_TITULAR: formatNumber(item["STATUS_TITULAR"]),
              FECHA_REEMPLAZO: formatDate(item.FECHA_REEMPLAZO),
              DIAS_REEMPLAZO: formatNumber(item["DIAS_REEMPLAZO"]),
              DIAS_CIERRE_REMP: formatNumber(item["DIAS_CIERRE_REMP"]),
            })
          );
        }

        // Se obtiene la información de los fuera de servicio.
        const responsefueraservicio = await getReporteFueraServicio(
          fecha,
          caso,
          SupId,
          ClienteId
        );

        // Se asignan los valores obtenidos a las variables correspondientes.
        const reporteFueraServicio =
          responsefueraservicio.datareportefueraservicio;
        fueraservicionum.value =
          reporteFueraServicio?.FLOTA_FUERA_SERVICIO || 0;
        fueraservicioporcentaje.value =
          reporteFueraServicio?.PORCENTAJE_FUERA_SERVICIO || 0;

        // Si la el segundo dataset es un array va llenando la tabla con la información.
        if (
          reporteFueraServicio &&
          Array.isArray(reporteFueraServicio.datafueraservicio)
        ) {
          fueraservicioInfo.value = reporteFueraServicio.datafueraservicio.map(
            (item) => ({
              ...item,
              FECHA_FS: formatDate(item.FECHA_FS),
              DIAS_FS: formatNumber(item["DIAS_FS"]),
              FECHA_ENTREGA: formatDate(item.FECHA_ENTREGA),
            })
          );
        }

        // Marcamos que los datos han sido cargados.
        dataLoaded.value = true;
      } catch (error) {
        console.error("Error al obtener la información:", error);
        dataLoaded.value = true;
      }
    };

    // Función para formatear números.
    const formatNumber = (value) => {
      if (
        value === "-" ||
        value === "N/A" ||
        value === null ||
        value === "NULL" ||
        value === "null"
      ) {
        return "0";
      }
      if (value === "OT Abierta") {
        return value;
      }
      const truncatedValue = Math.trunc(value * 100) / 100;
      return new Intl.NumberFormat().format(truncatedValue);
    };

    // Función para formatear fechas.
    const formatDate = (value) => {
      if (value === "Pendiente" || !value) {
        return value || "N/A";
      }
      return new Date(value).toLocaleDateString("es-CL");
    };

    // Se llama a loadData cuando se monta el componente.
    onMounted(loadData);

    // Se retornan los datos necesarios para la vista.
    return {
      reemplazosInfo,
      fueraservicioInfo,
      reemplazosnum,
      reemplazosentrega,
      fueraservicionum,
      fueraservicioporcentaje,
      dataLoaded,
      selectedClient,
      selectedSup,
    };
  },
};
</script>

<!-- css para el ícono de carga. -->
<style scoped>
@import "../css/spinner.css";
</style>
