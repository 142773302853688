// Se importa lo necesario para la correcta llamada a la base de datos (se importa api).
import { api } from "../services/api.js";

// Función que entregara la inforamción al detalle, númerica y porcentual de la Flota.
export async function getReporteFlota(fecha, caso, id_supervisor, id_cliente) {
  try {
    const response = await api.get(
      `/reportes/flota?fecha='${fecha}'&caso=${caso}&id_supervisor=${id_supervisor}&id_cliente=${id_cliente}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener el reporte flota:", error);
    throw error;
  }
}
