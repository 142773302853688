// Se importa lo necesario para la correcta llamada a la base de datos (se importa api).
import { api } from "../services/api.js";

// INFO CLIENTES POR SUPERVISOR
// Información (nombre) de los clientes por supervisor.
export async function getReporteClientesSupervisor(fecha, id_supervisor) {
  try {
    const response = await api.get(
      `/reportes/clientes_supervisor?fecha='${fecha}'&id_supervisor=${id_supervisor}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error al obtener el reporte de los clientes del supervisor seleccionado:",
      error
    );
    throw error;
  }
}

// INFO CLIENTES GENERAL
// Información (nombre) de todos los clientes LOP activos.
export async function getReporteClientesGeneral(fecha, id_supervisor) {
  try {
    const response = await api.get(
      `/reportes/clientes_general?fecha='${fecha}'&id_supervisor=${id_supervisor}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error al obtener el reporte de los clientes de los supervisores LOP:",
      error
    );
    throw error;
  }
}
