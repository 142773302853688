import { defineStore } from "pinia";
import { getReporteSupervisores } from "../api/supervisores.js";
import useUserStore from "./userStore.js"; // Importar la tienda de usuario

// Se crea el almacén de supervisores.
export const useSupStore = defineStore("sup", {
  state: () => ({
    // Se inicializan las variables.
    selectedSup: null,
    supervisors: [],
  }),
  actions: {
    // Se crean las acciones para seleccionar un supervisor, limpiar la selección y cargar los supervisores.
    setSelectedSup(supervisor) {
      this.selectedSup = supervisor;
    },

    // Función para limpiar la selección de supervisor.
    clearSelectedSup() {
      this.selectedSup = null;
    },

    // Función para cargar los supervisores.
    async loadSupervisors() {
      const userStore = useUserStore();
      const user = userStore.user;
      const userRole = user?.rol;
      const userId = user?.id;

      try {
        // Formato de fecha para la consulta
        const fecha = new Date().toISOString().split("T")[0];

        // Caso en que el usuario tiene rol de administrador (cargar todos los supervisores activos).
        if (userRole === "admin") {
          const response = await getReporteSupervisores(fecha);
          const reporteSupervisores = response.datasupervisores;

          // Si el segundo dataset (`datasup`) es un array, se asignan los datos
          if (
            reporteSupervisores &&
            Array.isArray(reporteSupervisores.datasupervisores)
          ) {
            this.supervisors = reporteSupervisores.datasupervisores.map(
              (supervisor) => ({
                unikey_SU: supervisor.Sup_Cod_Cartera,
                name_SU: supervisor.Sup_Nombre,
              })
            );
          }

          // Agregar supervisor GENERAL al inicio de la lista.
          this.supervisors.unshift({
            unikey_SU: 100,
            name_SU: "GENERAL",
          });
        } else if (userRole === "manager") {
          // Caso en que el usuario tiene rol de manager (es un supervisor LOP).
          this.supervisors = [
            {
              unikey_SU: userId,
              name_SU: userStore.user?.name || "Manager",
            },
          ];

          // Se establece el supervisor seleccionado como el primer supervisor de la lista.
          this.setSelectedSup(this.supervisors[0]);
        }
      } catch (error) {
        console.error("Error fetching supervisors:", error);
      }
    },
  },
});
