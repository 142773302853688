<template>
  <div>
    <h1>Autologin</h1>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import useUserStore from "@/stores/userStore";
import {onBeforeMount} from "vue";

onBeforeMount(async () => {
  try {
    const router = useRouter();
    const userStore = useUserStore();
    const { token } = router.currentRoute.value.query;

    await userStore.autoLoginFromQuery(token);
    await router.push({ name: "Home" });
  } catch (err) {
    alert("Nombre o contraseña incorrectos");
  }
});

</script>
