// Se importa lo necesario para la correcta llamada a la base de datos (se importa api).
import { api } from "../services/api.js";

// Función que entregara la inforamción de flota, fuera de servicio, reemplazos y próximos contratos a vencer por cada cliente del supervisor.
export async function getVistaClientesSupervisor(fecha, caso, id_supervisor) {
  try {
    const response = await api.get(
      `/reportes/vistaclientessupervisor?fecha='${fecha}'&caso=${caso}&id_supervisor=${id_supervisor}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener la información:", error);
    throw error;
  }
}

// Función que entregara la inforamción de flota, fuera de servicio, reemplazos y próximos contratos a vencer por cada cliente de cada supervisor LOP.
export async function getVistaClientesGeneral(fecha, caso, id_supervisor) {
  try {
    const response = await api.get(
      `/reportes/vistaclientesgeneral?fecha='${fecha}'&caso=${caso}&id_supervisor=${id_supervisor}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener la información:", error);
    throw error;
  }
}
