// Se importan los elementos necesarios para crear las rutas desde vue-router.
import { createRouter, createWebHistory } from "vue-router";
// Se las vistas a utilizar en el proyecto.
import Login from "../components/Login.vue";
import AutoLogin from "../components/AutoLogin.vue";
import Home from "../views/HomeView.vue";
import Historic from "../views/Home_Historico.vue";
import Clientes from "../views/Clientes.vue";
import FlotaUnidadesNuevasPreLOP from "../views/Flota_PreLOP_Unidades_Nuevas.vue";
import Cpa from "../views/CPA.vue";
import MantencionesyRevisionesPendientes from "../views/Mantenciones_Revisiones_Pendientes.vue";
import ReemplazosFueraServicio from "../views/Reemplazos_Fuera_Servicio.vue";
// import BlankPage from "../components/Credenciales.vue";
import useUserStore from "@/stores/userStore";
import CPAHistorico from "@/views/CPA_Historico.vue";
import FlotaUnidadesNuevasPreLOPHistorico from "@/views/Flota_PreLOP_Unidades_Nuevas_Historico.vue";
import MantencionesyRevisionesPendientesHistorico from "@/views/Mantenciones_Revisiones_Pendientes_Historico.vue";
import ReemplazosFueraServicioHistorico from "@/views/Reemplazos_Fuera_Servicio_Historico.vue";

// Se definen todas las rutas.
const routes = [
  {
    path: "/Login",
    meta: {
      free: true,
    },
    children: [
      { path: "", name: "Login", component: Login },
      {
        path: "auto",
        name: "AutoLogin",
        component: AutoLogin,
      },
    ],
  },

  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      admin: true,
      manager: true,
      requiresAuth: true,
    },
  },
  {
    path: "/Historic",
    name: "Historic",
    component: Historic,
    meta: {
      admin: true,
      manager: true,
      requiresAuth: true,
    },
  },
  {
    path: "/Clientes",
    name: "Clientes",
    component: Clientes,
    meta: {
      admin: true,
      manager: true,
      requiresAuth: true,
    },
  },
  // {
  //   path: "/BlankPage",
  //   name: "BlankPage",
  //   component: BlankPage,
  //   meta: {
  //     free: true,
  //   },
  // },
  {
    path: "/FlotaUnidadesNuevasPreLOP",
    name: "FlotaUnidadesNuevasPreLOP",
    component: FlotaUnidadesNuevasPreLOP,
    meta: {
      admin: true,
      manager: true,
      requiresAuth: true,
    },
  },
  {
    path: "/FlotaUnidadesNuevasPreLOPHistorico",
    name: "FlotaUnidadesNuevasPreLOPHistorico",
    component: FlotaUnidadesNuevasPreLOPHistorico,
    meta: {
      admin: true,
      manager: true,
      requiresAuth: true,
    },
  },
  {
    path: "/Cpa",
    name: "Cpa",
    component: Cpa,
    meta: {
      admin: true,
      manager: true,
      requiresAuth: true,
    },
  },
  {
    path: "/CpaHistorico",
    name: "CpaHistorico",
    component: CPAHistorico,
    meta: {
      admin: true,
      manager: true,
      requiresAuth: true,
    },
  },
  {
    path: "/MantencionesyRevisionesPendientes",
    name: "MantencionesyRevisionesPendientes",
    component: MantencionesyRevisionesPendientes,
    meta: {
      admin: true,
      manager: true,
      requiresAuth: true,
    },
  },
  {
    path: "/MantencionesyRevisionesPendientesHistorico",
    name: "MantencionesyRevisionesPendientesHistorico",
    component: MantencionesyRevisionesPendientesHistorico,
    meta: {
      admin: true,
      manager: true,
      requiresAuth: true,
    },
  },
  {
    path: "/ReemplazosFueraServicio",
    name: "ReemplazosFueraServicio",
    component: ReemplazosFueraServicio,
    meta: {
      admin: true,
      manager: true,
      requiresAuth: true,
    },
  },
  {
    path: "/ReemplazosFueraServicioHistorico",
    name: "ReemplazosFueraServicioHistorico",
    component: ReemplazosFueraServicioHistorico,
    meta: {
      admin: true,
      manager: true,
      requiresAuth: true,
    },
  },
];

// Se crea el enrutador con todos los elementos necesarios para su correcto funcionamiento.
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

// Se define una función para verificar si el usuario está autenticado y si tiene los permisos necesarios para acceder a las rutas.
router.beforeEach((to, from, next) => {
  const userStore = useUserStore();

  // Verifica si la ruta es libre (es decir, no requiere autenticación)
  const isFreeRoute = to.matched.some((record) => record.meta.free);

  // Verifica si el usuario está autenticado (revisa el token)
  const isAuthenticated = !!userStore.token;

  // Si la ruta es libre, permite el acceso sin autenticación
  if (isFreeRoute) {
    next();
    return;
  }

  // Si el usuario no está autenticado, redirigir al login
  if (!isAuthenticated) {
    if (to.name !== "Login") {
      next({ name: "Login" });
    } else {
      next();
    }
    return;
  }

  // Si el usuario está autenticado, verifica los roles y permisos
  const roleUser = userStore.user?.rol;

  // if (to.name === "BlankPage") {
  //   next();
  //   return;
  // }

  // Si el usuario está intentando acceder a la página de BlankPage, no redirigir en bucle
  // if (to.name === "BlankPage") {
  //   return next();
  // }

  // Permitir acceso si el usuario tiene el rol "manager" y la ruta lo permite
  if (
    roleUser === "manager" &&
    to.matched.some((record) => record.meta.manager)
  ) {
    next();
    return;
  }

  // Permitir acceso si el usuario tiene el rol "admin" y la ruta lo permite
  if (roleUser === "admin" && to.matched.some((record) => record.meta.admin)) {
    next();
    return;
  }

  // Si el usuario está autenticado pero no tiene permisos, redirigir al home
  if (to.name !== "Home") {
    next({ name: "Home" });
  } else {
    next();
  }
});

// Se exporta el enrutador.
export default router;
