// Se importa lo necesario para la correcta llamada a la base de datos (se importa api).
import { api } from "../services/api.js";

// Función para obtener la métrica CPA General (detalle, numérica y porcentual).
export async function getReporteMetricaGeneralCPA(
  fecha,
  metrica,
  id_supervisor
) {
  try {
    const response = await api.get(
      `/reportes/metrica_cpa_general?fecha='${fecha}'&metrica=${metrica}&id_supervisor=${id_supervisor}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener el reporte CPA:", error);
    throw error;
  }
}

// Función para obtener la métrica Mantenciones4D General (detalle, numérica y porcentual).
export async function getReporteMetricaGeneralMantenciones4D(
  fecha,
  metrica,
  id_supervisor
) {
  try {
    const response = await api.get(
      `/reportes/metrica_mantenciones4d_general?fecha='${fecha}'&metrica=${metrica}&id_supervisor=${id_supervisor}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener el reporte Mantenciones4D:", error);
    throw error;
  }
}

// Función para obtener la métrica MantencionesEST General (detalle, numérica y porcentual).
export async function getReporteMetricaGeneralMantencionesEST(
  fecha,
  metrica,
  id_supervisor
) {
  try {
    const response = await api.get(
      `/reportes/metrica_mantencionesest_general?fecha='${fecha}'&metrica=${metrica}&id_supervisor=${id_supervisor}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener el reporte MantencionesEST:", error);
    throw error;
  }
}

// Función para obtener la métrica RevisionesTecnicas General (detalle, numérica y porcentual).
export async function getReporteMetricaGeneralRevisionesTecnicas(
  fecha,
  metrica,
  id_supervisor
) {
  try {
    const response = await api.get(
      `/reportes/metrica_revisionestecnicas_general?fecha='${fecha}'&metrica=${metrica}&id_supervisor=${id_supervisor}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener el reporte RevisionesTecnicas:", error);
    throw error;
  }
}

// Función para obtener la métrica RevisionesGases General (detalle, numérica y porcentual).
export async function getReporteMetricaGeneralRevisionesGases(
  fecha,
  metrica,
  id_supervisor
) {
  try {
    const response = await api.get(
      `/reportes/metrica_revisionesgases_general?fecha='${fecha}'&metrica=${metrica}&id_supervisor=${id_supervisor}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener el reporte RevisionesGases:", error);
    throw error;
  }
}

// Función para obtener la métrica Reemplazos General (detalle, numérica y promedio).
export async function getReporteMetricaGeneralReemplazos(
  fecha,
  metrica,
  id_supervisor
) {
  try {
    const response = await api.get(
      `/reportes/metrica_reemplazos_general?fecha='${fecha}'&metrica=${metrica}&id_supervisor=${id_supervisor}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener el reporte Reemplazos:", error);
    throw error;
  }
}

// Función para obtener la métrica Unidades Nuevas General (detalle, numérica y promedio).
export async function getReporteMetricaGeneralUnidadesNuevas(
  fecha,
  metrica,
  id_supervisor
) {
  try {
    const response = await api.get(
      `/reportes/metrica_unidadesnuevas_general?fecha='${fecha}'&metrica=${metrica}&id_supervisor=${id_supervisor}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener el reporte Unidades Nuevas:", error);
    throw error;
  }
}

// Función para obtener la métrica Unidades Nuevas General (detalle, numérica y porcentual).
export async function getReporteMetricaGeneralProximosVencimientos(
  fecha,
  metrica,
  id_supervisor
) {
  try {
    const response = await api.get(
      `/reportes/metrica_proximosvencimientos_general?fecha='${fecha}'&metrica=${metrica}&id_supervisor=${id_supervisor}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener el reporte Próximos Vencimeintos:", error);
    throw error;
  }
}
