import { defineStore } from "pinia";
import { useClientStore } from "./clientStore";
import { useSupStore } from "./supStore";
import { jwtDecode } from "jwt-decode";
import axios from "axios";

class User {
  constructor(json) {
    this.username = json?.username ?? "";
    this.nombres = json?.nombres ?? "";
    this.apellidos = json?.apellidos ?? "";
    this.id = json?.id ?? 0;
    this.rol = json?.rol ?? "";
  }
}

const useUserStore = defineStore("user", {
  state: () => ({
    token: sessionStorage.getItem("authToken") || null,
    user: null,
    id: null,
    rol: null,
    loading: true,
  }),

  getters: {
    isAuthenticated() {
      return !!this.user;
    },
  },

  actions: {
    // Método para autenticar el usuario
    async authenticateUser(userName, password) {
      this.loading = true;
      try {
        const userdata = await axios.post(
          `${process.env.VUE_APP_API_URL}/auth/login`,
          { username: userName, password }
        );
        const { user, access_token } = userdata.data; //refresh_token
        const { username, nombres, apellidos } = user;
        const { codigoCartera: id, rol } = userdata.data.secciones[0];

        this.user = new User({ username, nombres, apellidos, id, rol });
        this.token = access_token;
        // this.refreshToken = refresh_token;

        // Guardar en sessionStorage
        sessionStorage.setItem("authToken", this.token);
        // sessionStorage.setItem("refreshToken", this.refreshToken);
        sessionStorage.setItem("user", JSON.stringify(this.user));
      } catch (error) {
        console.error("Error al autenticar usuario:", error);
        throw error;
      } finally {
        this.loading = false;
      }
    },

    // Método para cargar el usuario desde sessionStorage
    loadUserFromStorage() {
      this.loading = true;
      try {
        const storedToken = sessionStorage.getItem("authToken");
        // const storedRefreshToken = sessionStorage.getItem("refreshToken");

        if (storedToken) {
          const appStarted = sessionStorage.getItem("appStarted");

          // Verificar si la aplicación ya fue iniciada
          if (!appStarted) {
            sessionStorage.setItem("appStarted", "true");
          }

          const storedUser = sessionStorage.getItem("user");

          if (new Date().getTime() < jwtDecode(storedToken, {}).exp * 1000) {
            if (storedUser && storedToken) {
              this.user = JSON.parse(storedUser);
              this.token = storedToken;
              // this.refreshToken = storedRefreshToken;

              this.rol = this.user?.rol || null;
              this.id = this.user?.id || null;
            } else {
              this.logout();
            }
          } else {
            // this.refreshAuthToken();
          }
        }
      } catch (error) {
        console.error(
          "Error al cargar el usuario desde el almacenamiento:",
          error
        );
      } finally {
        this.loading = false;
      }
    },

    // async refreshAuthToken() {
    //   try {
    //     const response = await axios.post(
    //       `${process.env.VUE_APP_API_URL}/auth/refresh-token`,
    //       { refresh_token: this.refreshToken }
    //     );

    //     const newAccessToken = response.data.access_token;
    //     this.token = newAccessToken;

    //     sessionStorage.setItem("authToken", this.token);
    //   } catch (error) {
    //     console.error("Error al renovar el token:", error);
    //     this.logout();
    //   }
    // },

    async autoLoginFromQuery(token) {
      const userdata = await axios.post(
        `${process.env.VUE_APP_API_URL}/auth/autoLogin`,
        { token }
      );

      const { username, nombres, apellidos } = userdata.data.user;
      const access_token = userdata.data.access_token;
      const id = userdata.data.secciones[0].codigoCartera;
      const rol = userdata.data.secciones[0].rol;

      this.user = new User({
        username,
        nombres,
        apellidos,
        id,
        rol,
      });

      this.token = access_token;
      sessionStorage.setItem("authToken", this.token);
      // sessionStorage.setItem("refreshToken", this.refreshToken);
      sessionStorage.setItem("user", JSON.stringify(this.user));
      sessionStorage.setItem("appStarted", "true");
    },

    // Método para cerrar sesión
    logout() {
      this.token = null;
      this.user = null;
      sessionStorage.removeItem("authToken");
      // sessionStorage.removeItem("refreshToken");
      sessionStorage.removeItem("user");
      sessionStorage.setItem("appStarted", "false");

      // Limpiar otros stores relacionados
      const clientStore = useClientStore();
      clientStore.clearSelectedClient();

      const supStore = useSupStore();
      supStore.clearSelectedSup();
    },
  },
});

export default useUserStore;
