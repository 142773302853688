<template>
  <div class="margen_total">
    <div v-if="!dataLoaded" class="loading-spinner">
      <i class="fa-solid fa-spinner fa-spin"></i>
      <div class="spinner"></div>
    </div>
    <!-- Se utiliza el componente Tabla para mostrar la información respecto a la flota asociada al cliente. -->
    <!-- Posteriormente se define y se entregan todos los parámetros necesarios para que la tabla se visualice correctamente. -->
    <div class="table-container">
      <Tabla
        v-if="dataLoaded && flotaInfo.length > 0"
        :Info="flotaInfo"
        :subnum1="flotanum"
        :subnum2="flotaporcentaje + '%'"
        :columnHeaders="[
          'PPU',
          'Vehículo',
          'Región',
          'Kilometraje',
          'KM MP',
          'Fecha MP',
        ]"
        :rowFields="[
          { key: 'PPU', format: 'text' },
          { key: 'VEHICULO', format: 'text' },
          { key: 'REGION', format: 'text' },
          { key: 'KM', format: 'number' },
          { key: 'KM_MP', format: 'number' },
          { key: 'FECHA_MP', format: 'date' },
        ]"
        tableTitle="FLOTA"
        subtable1="# UNIDADES EN FLOTA"
        subtable2="% FLOTA CLIENTE VS FLOTA SUPERVISOR"
        :numColspan="6"
        :subnumColspan="3"
        :dataColspan="3"
        :enablePopup="false"
        :enableFilters="[true, true, true, true, true, true]"
      />
      <!-- En caso de que no existan datos, la vista mostrará al usuario que dicho cliente no tiene una flota asociada. -->
      <p v-else-if="dataLoaded && flotaInfo.length === 0">
        El cliente {{ selectedClient.name_CL }} no tiene asociada ninguna
        unidad.
      </p>
    </div>

    <!-- Se utiliza el componente Tabla para mostrar la información respecto a las unidades nuevas asociadas al cliente. -->
    <!-- Posteriormente se define y se entregan todos los parámetros necesarios para que la tabla se visualice correctamente. -->
    <div class="table-container">
      <Tabla
        v-if="dataLoaded && unidadesnuevasInfo.length > 0"
        :Info="unidadesnuevasInfo"
        :subnum1="unidadesnuevasnum"
        :subnum2="unidadesnuevaspromedio"
        :columnHeaders="[
          'PPU',
          'Codigo',
          'Region',
          'Ubicación',
          'Fecha Disponible',
        ]"
        :rowFields="[
          { key: 'Patente', format: 'text' },
          { key: 'CODIGO', format: 'text' },
          { key: 'Region', format: 'text' },
          { key: 'Ubicacion', format: 'text' },
          { key: 'FECHA_DISPONIBLE', format: 'date' },
        ]"
        tableTitle="UNIDADES NUEVAS"
        subtable1="# UNIDADES PENDIENTES DE ENTREGA"
        subtable2="PROMEDIO DÍAS DE DISPONIBILIDAD"
        :numColspan="5"
        :subnumColspan="3"
        :dataColspan="3"
        :enablePopup="false"
        :enableFilters="[true, true, true, true]"
        :disabled="false"
      />
      <!-- En caso de que no existan datos, la vista mostrará al usuario que dicho cliente no tiene unidades nuevas asociadas. -->
      <p v-else-if="dataLoaded && unidadesnuevasInfo.length === 0">
        El cliente {{ selectedClient.name_CL }} no presenta unidades nuevas
        asociadas.
      </p>
    </div>

    <!-- Se utiliza el componente Tabla para mostrar la información respecto a las unidades Pre-LOP asociadas al cliente. -->
    <!-- Posteriormente se define y se entregan todos los parámetros necesarios para que la tabla se visualice correctamente. -->
    <div class="table-container">
      <Tabla
        v-if="dataLoaded && unidadesprelopInfo.length > 0"
        :Info="unidadesprelopInfo"
        :subnum1="unidadesprelopnum"
        :subnum2="unidadespreloppromedio"
        :columnHeaders="[
          'PPU',
          'Codigo',
          'Región',
          'Fecha Entrega',
          'Días PRE-LOP',
        ]"
        :rowFields="[
          { key: 'PPU', format: 'text' },
          { key: 'CODIGO', format: 'text' },
          { key: 'REGION', format: 'text' },
          { key: 'FECHA_ENTREGA', format: 'date' },
          { key: 'DIAS_PRE_LOP', format: 'number' },
        ]"
        tableTitle="UNIDADES PRE-LOP"
        subtable1="# UNIDADES PRE-LOP"
        subtable2="PROMEDIO DÍAS PRE-LOP"
        :numColspan="5"
        :subnumColspan="3"
        :dataColspan="3"
        :enablePopup="false"
        :enableFilters="[true, true, true, true]"
        :disabled="false"
      />
      <!-- En caso de que no existan datos, la vista mostrará al usuario que dicho cliente no tiene unidades Pre-LOP asociadas. -->
      <p v-else-if="dataLoaded && unidadesprelopInfo.length === 0">
        El cliente {{ selectedClient.name_CL }} no presenta unidades Pre-LOP
        asociadas.
      </p>
    </div>
  </div>
</template>

<script>
// Se importan los elementos necesarios para el correcto funcionamiento de la vista.
// Se importa el componente Tabla para mostrar la información obtenida a través de la consulta a la API.
import { ref, computed, onMounted } from "vue";
import Tabla from "../components/Tabla_botones.vue";
// Se importan las tiendas para obtener los datos del cliente y supervisor seleccionados.
import { useClientStore } from "../stores/clientStore.js";
import { useSupStore } from "../stores/supStore.js";
// Se importa la tienda de usuario.
import useUserStore from "../stores/userStore.js";
// Se importan las funciones para obtener la información de la base de datos.
import { getReporteFlota } from "../api/flota.js";
import { getReporteUnidadesNuevas } from "../api/unidadesnuevas.js";
import { getReportePreLop } from "../api/unidadesprelop.js";

export default {
  // Se define el componente Tabla que se utilizará para mostrar la información de las mantenciones y revisiones pendientes asociadas al cliente.
  components: {
    Tabla,
  },
  setup() {
    // Se crean las listas que almacenarán toda la información obtenida de la consulta a la API.
    const flotaInfo = ref([]);
    const unidadesnuevasInfo = ref([]);
    const unidadesprelopInfo = ref([]);

    const dataLoaded = ref(false);

    // Constantes para valores numéricos de flota, unidades nuevas y pre-LOP.
    const flotanum = ref(null);
    const flotaporcentaje = ref(null);
    const unidadesnuevasnum = ref(null);
    const unidadesnuevaspromedio = ref(null);
    const unidadesprelopnum = ref(null);
    const unidadespreloppromedio = ref(null);

    // Se crean las variables clientStore y supStore para obtener los datos del cliente y supervisor seleccionados.
    const clientStore = useClientStore();
    const supStore = useSupStore();
    const userStore = useUserStore();

    // Se crean las variables selectedSup y selectedClient que permitirán obtener el supervisor y el cliente seleccionado.
    const selectedClient = computed(() => clientStore.selectedClient);
    const selectedSup = computed(() => supStore.selectedSup);

    // Función que permite cargar los datos obtenidos a través de la consulta a la API.
    const loadData = async () => {
      try {
        // Se utiliza la tienda de usuarios para obtener el rol y el id del usuario.
        const userRole = userStore.user?.role;
        const userId = userStore.user?.id;

        // Se inicializa la variable Sup_Cod_Cartera en null.
        let Sup_Cod_Cartera = null;

        // Asignación de Sup_Cod_Cartera dependiendo del rol.
        if (userRole === "manager") {
          Sup_Cod_Cartera = userId;
        } else if (selectedSup.value && selectedSup.value.unikey_SU) {
          Sup_Cod_Cartera = selectedSup.value.unikey_SU;
        }

        // Se asigna el valor de Numero_Cliente dependiendo del cliente seleccionado.
        const Numero_Cliente = selectedClient.value.unikey_CL;

        // Se asigna el valor de caso dependiendo de los valores de Sup_Cod_Cartera y Numero_Cliente.
        let caso;
        if (Sup_Cod_Cartera === 100 && Numero_Cliente === 100) {
          caso = "General-General";
        } else if (Sup_Cod_Cartera !== 100 && Numero_Cliente === 100) {
          caso = "General-Supervisor";
        } else if (Sup_Cod_Cartera === 100 && Numero_Cliente !== 100) {
          caso = "General-Cliente";
        } else {
          caso = "Supervisor-Cliente";
        }

        // Se asigna el valor de fecha dependiendo de la fecha seleccionada.
        const fecha = new Date().toISOString().split("T")[0];

        // Se asignan los valores de SupId y ClienteId dependiendo de los valores de Sup_Cod_Cartera y Numero_Cliente.
        const SupId = Sup_Cod_Cartera;
        const ClienteId = selectedClient.value.unikey_CL;

        // Se realiza la consulta a la API para obtener los datos de la flota del cliente seleccionado.
        const responseflota = await getReporteFlota(
          fecha,
          caso,
          SupId,
          ClienteId
        );

        // Se asignan los valores obtenidos a las variables correspondientes.
        const reporteFlota = responseflota?.datareporteflota;
        flotanum.value = reporteFlota?.FLOTA_CLI || 0;
        flotaporcentaje.value = reporteFlota?.PORCENTAJE;

        if (reporteFlota && Array.isArray(reporteFlota.dataflota)) {
          flotaInfo.value = reporteFlota.dataflota.map((item) => ({
            ...item,
            KM: formatNumber(item["KM"]),
            KM_MP: formatNumber(item["KM_MP"]),
            FECHA_MP: formatDate(item.FECHA_MP),
          }));
        }

        // Se realiza la consulta a la API para obtener los datos de las unidades nuevas del cliente seleccionado.
        const responseunidadesnuevas = await getReporteUnidadesNuevas(
          fecha,
          caso,
          SupId,
          ClienteId
        );

        // Se asignan los valores obtenidos a las variables correspondientes.
        const reporteUnidadesNuevas =
          responseunidadesnuevas?.datareporteunidadesnuevas;
        unidadesnuevasnum.value =
          reporteUnidadesNuevas?.UNIDADES_PENDIENTES || 0;
        unidadesnuevaspromedio.value =
          reporteUnidadesNuevas?.DIAS_PROMEDIO_DISPONIBILIDAD || 0;

        if (
          reporteUnidadesNuevas &&
          Array.isArray(reporteUnidadesNuevas.dataunidadesnuevas)
        ) {
          unidadesnuevasInfo.value =
            reporteUnidadesNuevas.dataunidadesnuevas.map((item) => ({
              ...item,
              FECHA_DISPONIBLE: formatDate(item.FECHA_DISPONIBLE),
            }));
        }

        // Se realiza la consulta a la API para obtener los datos de las unidades Pre-LOP del cliente seleccionado.
        const responseprelop = await getReportePreLop(
          fecha,
          caso,
          SupId,
          ClienteId
        );

        // Se asignan los valores obtenidos a las variables correspondientes.
        const reportePreLop = responseprelop?.datareporteprelop;
        unidadesprelopnum.value = reportePreLop?.UNIDADES_PRE_LOP || 0;
        unidadespreloppromedio.value =
          reportePreLop?.PROMEDIO_DIAS_PRE_LOP || 0;

        if (reportePreLop && Array.isArray(reportePreLop.dataprelop)) {
          unidadesprelopInfo.value = reportePreLop.dataprelop.map((item) => ({
            ...item,
            FECHA_ENTREGA: formatDate(item.FECHA_ENTREGA),
            DIAS_PRE_LOP: formatNumber(item["DIAS_PRE_LOP"]),
          }));
        }

        // Una vez que se obtienen los datos, se marca como cargados (true).
        dataLoaded.value = true;
      } catch (error) {
        console.error("Error loading data:", error);
        dataLoaded.value = true;
        // Marca los datos como cargados incluso si hay un error para evitar el estado de carga infinito.
      }
    };

    // Se definen las funciones de formateo de datos.

    // Función para formatear números.
    const formatNumber = (value) => {
      if (value === "-" || value === 0 || value === "0" || value === "N/A") {
        return "0";
      }
      if (value === "OT Abierta") {
        return value;
      }
      return new Intl.NumberFormat().format(value);
    };

    // Función para formatear fechas.
    const formatDate = (value) => {
      if (value === "Pendiente" || !value) {
        return value || "N/A";
      }
      return new Date(value).toLocaleDateString("es-CL");
    };

    // Se llama a loadData cuando se monta el componente, solo si hay un supervisor seleccionado (utilizando onMounted).
    onMounted(loadData);

    // Se retornan los elementos necesarios para el correcto funcionamiento de la vista.
    return {
      flotaInfo,
      unidadesnuevasInfo,
      unidadesprelopInfo,
      dataLoaded,
      flotanum,
      unidadesnuevasnum,
      unidadesprelopnum,
      flotaporcentaje,
      unidadesnuevaspromedio,
      unidadespreloppromedio,
      selectedClient,
      selectedSup,
    };
  },
};
</script>

<!-- css para el ícono de carga. -->
<style scoped>
@import "../css/spinner.css";
</style>
