<template>
  <div class="margen_total">
    <div v-if="!dataLoaded" class="loading-spinner">
      <i class="fa-solid fa-spinner fa-spin"></i>
      <div class="spinner"></div>
    </div>

    <!-- Tabla para mostrar información de mantenciones por KM 4D pendientes -->
    <div class="table-container">
      <Tabla
        v-if="dataLoaded && mantencionespendientes4dInfo.length > 0"
        :Info="mantencionespendientes4dInfo"
        :subnum1="mantencionespendientes4dnum"
        :subnum2="mantencionespendientes4dporcentaje + '%'"
        :columnHeaders="[
          'PPU',
          'Codigo',
          'Región',
          'KM 4D',
          'KM MP',
          'Fecha MP',
          'KM Prox MP',
          'Exceso KMS 4D',
        ]"
        :rowFields="[
          { key: 'PPU', format: 'text' },
          { key: 'CODIGO', format: 'text' },
          { key: 'REGION', format: 'text' },
          { key: 'KM_4D', format: 'number' },
          { key: 'KM_MP', format: 'number' },
          { key: 'FECHA_MP', format: 'date' },
          { key: 'KM_PROX_MP', format: 'number' },
          { key: 'EXCESO_4D', format: 'number' },
        ]"
        tableTitle="MANTENCIONES POR KM 4D PENDIENTES"
        subtable1="# UNIDADES PENDIENTES"
        subtable2="% UNIDADES PENDIENTES"
        :numColspan="8"
        :subnumColspan="4"
        :dataColspan="4"
        :enablePopup="false"
        :enableFilters="[true, true, true, true, true, true, true, true]"
        :disabled="false"
      />
      <!-- Mensaje para los casos en que no hay datos. -->
      <p v-else-if="dataLoaded && mantencionespendientes4dInfo.length === 0">
        El cliente {{ selectedClient.name_CL }} no presenta un registro
        histórico de las unidades con mantenciones por kilometraje en 4D
        pendientes en la fecha seleccionada.
      </p>
    </div>

    <!-- Tabla para mostrar información de mantenciones por días pendientes -->
    <div class="table-container">
      <Tabla
        v-if="dataLoaded && mantencionespendientesestInfo.length > 0"
        :Info="mantencionespendientesestInfo"
        :subnum1="mantencionespendientesestnum"
        :subnum2="mantencionespendientesestporcentaje + '%'"
        :columnHeaders="[
          'PPU',
          'Codigo',
          'Región',
          'KM MP',
          'Fecha MP',
          'KM Prox MP',
          'KM Est',
          'Exceso KMS Est',
        ]"
        :rowFields="[
          { key: 'PPU', format: 'text' },
          { key: 'CODIGO', format: 'text' },
          { key: 'REGION', format: 'text' },
          { key: 'KM_MP', format: 'number' },
          { key: 'FECHA_MP', format: 'date' },
          { key: 'KM_PROX_MP', format: 'number' },
          { key: 'KMS_EST', format: 'number' },
          { key: 'EXCESO_EST', format: 'number' },
        ]"
        tableTitle="MANTENCIONES POR ESTIMACIÓN PENDIENTES"
        subtable1="# UNIDADES PENDIENTES"
        subtable2="% UNIDADES PENDIENTES"
        :numColspan="8"
        :subnumColspan="4"
        :dataColspan="4"
        :enablePopup="false"
        :enableFilters="[true, true, true, true, true, true, true, true]"
        :disabled="false"
      />
      <!-- Mensaje para los casos en que no hay datos. -->
      <p v-else-if="dataLoaded && mantencionespendientesestInfo.length === 0">
        El cliente {{ selectedClient.name_CL }} no presenta un registro
        histórico de las unidades con mantenciones por días pendientes en la
        fecha seleccionada.
      </p>
    </div>

    <!-- Tabla para mostrar información de revisiones de gases pendientes -->
    <div class="table-container">
      <Tabla
        v-if="dataLoaded && revisionesgasespendientesInfo.length > 0"
        :Info="revisionesgasespendientesInfo"
        :subnum1="revisionesgasespendientesnum"
        :subnum2="revisionesgasespendientesporcentaje + '%'"
        :columnHeaders="['PPU', 'Codigo', 'Ubicación', 'Rev. Gases']"
        :rowFields="[
          { key: 'PPU', format: 'text' },
          { key: 'CODIGO', format: 'text' },
          { key: 'REGION', format: 'text' },
          { key: 'Rev_GASES', format: 'date' },
        ]"
        tableTitle="REVISIONES GASES PENDIENTES"
        subtable1="# FLOTA CON REVISIÓN GASES VENCIDA"
        subtable2="% FLOTA CON REVISIÓN GASES VENCIDA"
        :numColspan="4"
        :subnumColspan="2"
        :dataColspan="2"
        :enablePopup="false"
        :enableFilters="[true, true, true, true]"
        :disabled="false"
      />
      <!-- Mensaje para los casos en que no hay datos. -->
      <p v-else-if="dataLoaded && revisionesgasespendientesInfo.length === 0">
        El cliente {{ selectedClient.name_CL }} no presenta un registro
        histórico de las unidades con revisiones de gases pendientes en la fecha
        seleccionada.
      </p>
    </div>

    <!-- Tabla para mostrar información de revisiones de gases próximas a vencer (próximos 30 días) -->
    <div class="table-container">
      <Tabla
        v-if="dataLoaded && revisionesgasesproxvencerInfo.length > 0"
        :Info="revisionesgasesproxvencerInfo"
        :subnum1="revisionesgasesproxvencernum"
        :subnum2="revisionesgasesproxvencerporcentaje + '%'"
        :columnHeaders="['PPU', 'Codigo', 'Ubicación', 'Rev. Gases']"
        :rowFields="[
          { key: 'PPU', format: 'text' },
          { key: 'CODIGO', format: 'text' },
          { key: 'REGION', format: 'text' },
          { key: 'Rev_GASES', format: 'date' },
        ]"
        tableTitle="REVISIONES GASES PRÓXIMAS A VENCER"
        subtable1="# FLOTA CON REVISIÓN GASES PRÓXIMAS A VENCER"
        subtable2="% FLOTA CON REVISIÓN GASES PRÓXIMAS A VENCER"
        :numColspan="4"
        :subnumColspan="2"
        :dataColspan="2"
        :enablePopup="false"
        :enableFilters="[true, true, true, true]"
        :disabled="false"
      />
      <!-- Mensaje para los casos en que no hay datos. -->
      <p v-else-if="dataLoaded && revisionesgasesproxvencerInfo.length === 0">
        El cliente {{ selectedClient.name_CL }} no presenta un registro
        histórico de las unidades con revisiones de gases próximaS a vencer
        (próximos 30 días) en la fecha seleccionada.
      </p>
    </div>

    <!-- Tabla para mostrar información de revisiones técnicas pendientes -->
    <div class="table-container">
      <Tabla
        v-if="dataLoaded && revisionestecnicaspendientesInfo.length > 0"
        :Info="revisionestecnicaspendientesInfo"
        :subnum1="revisionestecnicaspendientesnum"
        :subnum2="revisionestecnicaspendientesporcentaje + '%'"
        :columnHeaders="['PPU', 'Codigo', 'Ubicación', 'Rev. Técnica']"
        :rowFields="[
          { key: 'PPU', format: 'text' },
          { key: 'CODIGO', format: 'text' },
          { key: 'REGION', format: 'text' },
          { key: 'Rev_TECNICA', format: 'date' },
        ]"
        tableTitle="REVISIONES TECNICAS PENDIENTES"
        subtable1="# FLOTA CON REVISIÓN TÉCNICA VENCIDA"
        subtable2="% FLOTA CON REVISIÓN TÉCNICA VENCIDA"
        :numColspan="4"
        :subnumColspan="2"
        :dataColspan="2"
        :enablePopup="false"
        :enableFilters="[true, true, true, true]"
        :disabled="false"
      />
      <!-- Mensaje para los casos en que no hay datos. -->
      <p
        v-else-if="dataLoaded && revisionestecnicaspendientesInfo.length === 0"
      >
        El cliente {{ selectedClient.name_CL }} no presenta un registro
        histórico de las unidades con revisiones técnicas pendientes en la fecha
        seleccionada.
      </p>
    </div>

    <!-- Tabla para mostrar información de revisiones técnicas próximas a vencer (próximos 30 días) -->
    <div class="table-container">
      <Tabla
        v-if="dataLoaded && revisionestecnicasproxvencerInfo.length > 0"
        :Info="revisionestecnicasproxvencerInfo"
        :subnum1="revisionestecnicasproxvencernum"
        :subnum2="revisionestecnicasproxvencerporcentaje + '%'"
        :columnHeaders="['PPU', 'Codigo', 'Ubicación', 'Rev. Técnica']"
        :rowFields="[
          { key: 'PPU', format: 'text' },
          { key: 'CODIGO', format: 'text' },
          { key: 'REGION', format: 'text' },
          { key: 'Rev_TECNICA', format: 'date' },
        ]"
        tableTitle="REVISIONES TECNICAS PRÓXIMAS A VENCER"
        subtable1="# FLOTA CON REVISIÓN TÉCNICA PRÓXIMAS A VENCER"
        subtable2="% FLOTA CON REVISIÓN TÉCNICA PRÓXIMAS A VENCER"
        :numColspan="4"
        :subnumColspan="2"
        :dataColspan="2"
        :enablePopup="false"
        :enableFilters="[true, true, true, true]"
        :disabled="false"
      />
      <!-- Mensaje para los casos en que no hay datos. -->
      <p
        v-else-if="dataLoaded && revisionestecnicasproxvencerInfo.length === 0"
      >
        El cliente {{ selectedClient.name_CL }} no presenta un registro
        histórico de las unidades con revisiones técnicas próximas a vencer
        (próximos 30 días) en la fecha seleccionada.
      </p>
    </div>
  </div>
</template>

<script>
// Importación de elementos necesarios y tiendas
import { ref, computed, onMounted } from "vue";
import Tabla from "../components/Tabla_botones.vue";
import { useClientStore } from "../stores/clientStore.js";
import { useSupStore } from "../stores/supStore.js";
import useUserStore from "../stores/userStore.js";
import { useDateStore } from "../stores/dateStore.js";
import { getReporteMantenciones4D } from "../api/mantencionespendientes4d.js";
import { getReporteMantencionesEST } from "../api/mantencionespendientesest.js";
import { getReporteRevisionesGases } from "../api/revisionesgasespendientes.js";
import { getReporteRevisionesTecnicas } from "../api/revisionestecnicaspendientes.js";
import { getReporteRevisionesGasesProxVencer } from "../api/revisionesgasesproxvencer.js";
import { getReporteRevisionesTecnicasProxVencer } from "../api/revisionestecnicasproxvencer.js";

export default {
  components: {
    Tabla,
  },
  setup() {
    // Variables reactivas para almacenar la información de las tablas.
    const mantencionespendientes4dInfo = ref([]);
    const mantencionespendientesestInfo = ref([]);
    const revisionesgasespendientesInfo = ref([]);
    const revisionestecnicaspendientesInfo = ref([]);
    const revisionesgasesproxvencerInfo = ref([]);
    const revisionestecnicasproxvencerInfo = ref([]);

    // Se establece una variable reactiva para indicar si los datos ya fueron cargados.
    const dataLoaded = ref(false);

    // Se utilizan las tiendas.
    const clientStore = useClientStore();
    const supStore = useSupStore();
    const userStore = useUserStore();
    const dateStore = useDateStore();

    // Se obtienen los valores de los elementos seleccionados en las tiendas.
    const selectedClient = computed(() => clientStore.selectedClient);
    const selectedSup = computed(() => supStore.selectedSup);
    const selectedDate = ref(dateStore.selectedDate || null);

    // Se definen las variables reactivas para almacenar los valores de los totales.
    const mantencionespendientes4dnum = ref(null);
    const mantencionespendientes4dporcentaje = ref(null);
    const mantencionespendientesestnum = ref(null);
    const mantencionespendientesestporcentaje = ref(null);
    const revisionestecnicaspendientesnum = ref(null);
    const revisionestecnicaspendientesporcentaje = ref(null);
    const revisionesgasespendientesnum = ref(null);
    const revisionesgasespendientesporcentaje = ref(null);
    const revisionesgasesproxvencernum = ref(null);
    const revisionesgasesproxvencerporcentaje = ref(null);
    const revisionestecnicasproxvencernum = ref(null);
    const revisionestecnicasproxvencerporcentaje = ref(null);

    // Función para cargar los datos de las tablas.
    const loadData = async () => {
      try {
        // Se obtienen los valores de los elementos seleccionados en las tiendas.
        const userRole = userStore.user?.role;
        const userId = userStore.user?.id;

        // Se establece el valor de Sup_Cod_Cartera según el rol del usuario.
        let Sup_Cod_Cartera = null;
        if (userRole === "manager") {
          Sup_Cod_Cartera = userId;
        } else if (selectedSup.value && selectedSup.value.unikey_SU) {
          Sup_Cod_Cartera = selectedSup.value.unikey_SU;
        }

        // Se establece el valor de Numero_Cliente según el cliente seleccionado.
        const Numero_Cliente = selectedClient.value.unikey_CL;

        // Se establece el caso según los valores de Sup_Cod_Cartera y Numero_Cliente.
        let caso;
        if (Sup_Cod_Cartera === 100 && Numero_Cliente === 100) {
          caso = "General-General";
        } else if (Sup_Cod_Cartera !== 100 && Numero_Cliente === 100) {
          caso = "General-Supervisor";
        } else if (Sup_Cod_Cartera === 100 && Numero_Cliente !== 100) {
          caso = "General-Cliente";
        } else {
          caso = "Supervisor-Cliente";
        }

        // Se formatea la fecha seleccionada.
        const fecha = new Date(selectedDate.value).toISOString().split("T")[0];

        // Se establecen los valores de SupId y ClienteId según los valores de Sup_Cod_Cartera y Numero_Cliente.
        const SupId = Sup_Cod_Cartera;
        const ClienteId = selectedClient.value.unikey_CL;

        // Se obtiene la información de las mantenciones pendientes por KM.
        const responsemantenciones4d = await getReporteMantenciones4D(
          fecha,
          caso,
          SupId,
          ClienteId
        );

        // Se asignan los valores obtenidos a las variables correspondientes.
        const reporteMantenciones4D =
          responsemantenciones4d.datareportemantenciones4d;
        mantencionespendientes4dnum.value =
          reporteMantenciones4D?.UNIDADES_PENDIENTES_4D || 0;
        mantencionespendientes4dporcentaje.value =
          reporteMantenciones4D?.PORCENTAJE_4D || 0;

        // Si el segundo dataset es un array va llenando la tabla con la información.
        if (
          reporteMantenciones4D &&
          Array.isArray(reporteMantenciones4D.datamantenciones4d)
        ) {
          mantencionespendientes4dInfo.value =
            reporteMantenciones4D.datamantenciones4d.map((item) => ({
              ...item,
              KM_4D: formatNumber(item["KM_4D"]),
              KM_MP: formatNumber(item["KM_MP"]),
              FECHA_MP: formatDate(item.FECHA_MP),
              KM_PROX_MP: formatNumber(item["KM_PROX_MP"]),
              EXCESO_4D: formatNumber(item["EXCESO_4D"]),
            }));
        }

        // Se obtiene la información de las mantenciones pendientes por día.
        const responsemantencionesest = await getReporteMantencionesEST(
          fecha,
          caso,
          SupId,
          ClienteId
        );

        // Se asignan los valores obtenidos a las variables correspondientes.
        const reporteMantencionesEST =
          responsemantencionesest.datareportemantencionesest;
        mantencionespendientesestnum.value =
          reporteMantencionesEST?.UNIDADES_PENDIENTES_EST || 0;
        mantencionespendientesestporcentaje.value =
          reporteMantencionesEST?.PORCENTAJE_EST || 0;

        // Si el segundo dataset es un array va llenando la tabla con la información.
        if (
          reporteMantencionesEST &&
          Array.isArray(reporteMantencionesEST.datamantencionesest)
        ) {
          mantencionespendientesestInfo.value =
            reporteMantencionesEST.datamantencionesest.map((item) => ({
              ...item,
              KM_MP: formatNumber(item["KM_MP"]),
              FECHA_MP: formatDate(item.FECHA_MP),
              KM_PROX_MP: formatNumber(item["KM_PROX_MP"]),
              KMS_EST: formatNumber(item["KMS_EST"]),
              EXCESO_EST: formatNumber(item["EXCESO_EST"]),
            }));
        }

        // REVISIONES GASES PENDIENTES
        // Se obtiene la información de las revisiones de gases pendientes.
        const responserevisionesgases = await getReporteRevisionesGases(
          fecha,
          caso,
          SupId,
          ClienteId
        );

        // Se asignan los valores obtenidos a las variables correspondientes.
        const reporteRevisionesGases =
          responserevisionesgases.datareporterevisionesgases;
        revisionesgasespendientesnum.value =
          reporteRevisionesGases?.FLOTA_REVISION_GASES_VENCIDA || 0;
        revisionesgasespendientesporcentaje.value =
          reporteRevisionesGases?.PORCENTAJE_GASES || 0;

        // Si el segundo dataset es un array va llenando la tabla con la información.
        if (
          reporteRevisionesGases &&
          Array.isArray(reporteRevisionesGases.datarevisionesgases)
        ) {
          revisionesgasespendientesInfo.value =
            reporteRevisionesGases.datarevisionesgases.map((item) => ({
              ...item,
              Rev_GASES: formatDate(item.Rev_GASES),
            }));
        }

        // REVISIONES GASES PRÓXIMAS A VENCER
        // Se obtiene la información de las revisiones de gases próximaS a vencer (próximos 30 días).
        const responserevisionesgasesproxvencer =
          await getReporteRevisionesGasesProxVencer(
            fecha,
            caso,
            SupId,
            ClienteId
          );

        // Se asignan los valores obtenidos a las variables correspondientes.
        const reporteRevisionesGasesProxVencer =
          responserevisionesgasesproxvencer.datareporterevisionesgasesproxvencer;
        revisionesgasesproxvencernum.value =
          reporteRevisionesGases?.FLOTA_REVISION_GASES_VENCIDA || 0;
        revisionesgasesproxvencerporcentaje.value =
          reporteRevisionesGases?.PORCENTAJE_GASES || 0;

        // Si el segundo dataset es un array va llenando la tabla con la información.
        if (
          reporteRevisionesGasesProxVencer &&
          Array.isArray(
            reporteRevisionesGasesProxVencer.datareporterevisionesgasesproxvencer
          )
        ) {
          revisionesgasesproxvencerInfo.value =
            reporteRevisionesGasesProxVencer.datareporterevisionesgasesproxvencer.map(
              (item) => ({
                ...item,
                Rev_GASES: formatDate(item.Rev_GASES),
              })
            );
        }

        // REVISIONES TECNICAS PENDIENTES
        // // Se obtiene la información de las revisiones técnicas pendientes.
        const responserevisionestecnicas = await getReporteRevisionesTecnicas(
          fecha,
          caso,
          SupId,
          ClienteId
        );

        // Se asignan los valores obtenidos a las variables correspondientes.
        const reporteRevisionesTecnicas =
          responserevisionestecnicas.datareporterevisionestecnicas;
        revisionestecnicaspendientesnum.value =
          reporteRevisionesTecnicas?.FLOTA_REVISION_TECNICA_VENCIDA || 0;
        revisionestecnicaspendientesporcentaje.value =
          reporteRevisionesTecnicas?.PORCENTAJE_TECNICA || 0;

        // Si el segundo dataset es un array va llenando la tabla con la información.
        if (
          reporteRevisionesTecnicas &&
          Array.isArray(reporteRevisionesTecnicas.datarevisionestecnicas)
        ) {
          revisionestecnicaspendientesInfo.value =
            reporteRevisionesTecnicas.datarevisionestecnicas.map((item) => ({
              ...item,
              Rev_TECNICA: formatDate(item.Rev_TECNICA),
            }));
        }

        // REVISIONES TECNICAS PRÓXIMAS A VENCER
        // Se obtiene la información de las revisiones técnicas pendientes.
        const responserevisionestecnicasproxvencer =
          await getReporteRevisionesTecnicasProxVencer(
            fecha,
            caso,
            SupId,
            ClienteId
          );

        // Se asignan los valores obtenidos a las variables correspondientes.
        const reporteRevisionesTecnicasProxVencer =
          responserevisionestecnicasproxvencer.datareporterevisionestecnicasproxvencer;
        revisionestecnicasproxvencernum.value =
          reporteRevisionesTecnicas?.FLOTA_REVISION_TECNICA_VENCIDA || 0;
        revisionestecnicasproxvencerporcentaje.value =
          reporteRevisionesTecnicas?.PORCENTAJE_TECNICA || 0;

        // Si el segundo dataset es un array va llenando la tabla con la información.
        if (
          reporteRevisionesTecnicasProxVencer &&
          Array.isArray(
            reporteRevisionesTecnicasProxVencer.datareporterevisionestecnicasproxvencer
          )
        ) {
          revisionestecnicasproxvencerInfo.value =
            reporteRevisionesTecnicasProxVencer.datareporterevisionestecnicasproxvencer.map(
              (item) => ({
                ...item,
                Rev_TECNICA: formatDate(item.Rev_TECNICA),
              })
            );
        }

        // Se establece el valor de dataLoaded en true para indicar que los datos ya fueron cargados.
        dataLoaded.value = true;
      } catch (error) {
        console.error("Error al obtener la información:", error);
        dataLoaded.value = true;
      }
    };

    // Se definen las funciones de formateo de datos.

    // Función para formatear números.
    const formatNumber = (value) => {
      if (value === "-" || value === 0 || value === "0" || value === "N/A") {
        return "0";
      }
      if (value === "OT Abierta") {
        return value;
      }
      return new Intl.NumberFormat().format(value);
    };

    // Función para formatear fechas.
    const formatDate = (value) => {
      if (value === "Pendiente" || !value) {
        return value || "N/A";
      }
      return new Date(value).toLocaleDateString("es-CL");
    };

    // Se cargan los datos al montar el componente.
    onMounted(loadData);

    // Se retornan los datos y funciones para la vista.
    return {
      mantencionespendientes4dInfo,
      mantencionespendientesestInfo,
      revisionesgasespendientesInfo,
      revisionestecnicaspendientesInfo,
      revisionesgasesproxvencerInfo,
      revisionestecnicasproxvencerInfo,
      mantencionespendientes4dnum,
      mantencionespendientesestnum,
      revisionesgasespendientesnum,
      revisionestecnicaspendientesnum,
      mantencionespendientes4dporcentaje,
      mantencionespendientesestporcentaje,
      revisionesgasespendientesporcentaje,
      revisionestecnicaspendientesporcentaje,
      revisionesgasesproxvencernum,
      revisionesgasesproxvencerporcentaje,
      revisionestecnicasproxvencernum,
      revisionestecnicasproxvencerporcentaje,
      dataLoaded,
      selectedClient,
      selectedSup,
    };
  },
};
</script>

<!-- css para el ícono de carga. -->
<style scoped>
@import "../css/spinner.css";
</style>
