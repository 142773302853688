// Se importa lo necesario para la correcta llamada a la base de datos (se importa api).
import { api } from "../services/api.js";

// Función que entregara la inforamción al detalle, númerica y porcentual de las Unidades Nuevas.
export async function getReporteUnidadesNuevas(
  fecha,
  caso,
  id_supervisor,
  id_cliente
) {
  try {
    const response = await api.get(
      `/reportes/unidadesnuevas?fecha='${fecha}'&caso=${caso}&id_supervisor=${id_supervisor}&id_cliente=${id_cliente}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener el reporte unidadesnuevas:", error);
    throw error;
  }
}
