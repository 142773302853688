// Se importa lo necesario para la correcta llamada a la base de datos (se importa api).
import { api } from "../services/api.js";

// Función que entregara la inforamción al detalle, númerica (normal y sobre 3 meses) de los CPA.
export async function getReporteCPA(fecha, caso, id_supervisor, id_cliente) {
  try {
    const response = await api.get(
      `/reportes/cpa?fecha='${fecha}'&caso=${caso}&id_supervisor=${id_supervisor}&id_cliente=${id_cliente}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener el reporte CPA:", error);
    throw error;
  }
}
