<template>
  <div class="layout-container-historico">
    <div class="sidemenu-container-historico">
      <SideMenu class="home-sidemenu-historico" />
      <ButtonsMenu class="home-menu-historico" />
    </div>

    <div class="main-container-historico">
      <div class="content-columns-historico">
        <div class="left-column-historico">
          <div class="datepicker-container-historico">
            <!-- Calendario para seleccionar fecha y cargar las métricas de ese respectivo día. -->
            <label for="datepicker" class="calendar-label"
              >Seleccionar Fecha:
            </label>
            <input
              type="date"
              id="datepicker"
              v-model="selectedDate"
              :max="today"
              @change="onDateChange"
              class="calendar-input"
            />
          </div>

          <!-- Botones. -->
          <div class="buttons-container-historico">
            <!-- Botón Flota. -->
            <Boton
              icon="fa-solid fa-car-side"
              primaryText="Flota histórica"
              secondaryText="Flota general, unidades nuevas y unidades pre-LOP históricas."
              to="/FlotaUnidadesNuevasPreLOPHistorico"
              :disabled="!isButtonEnabled"
              :class="{ disabled: !isButtonEnabled }"
            />
            <!-- Botón Servicios. -->
            <Boton
              icon="fa-solid fa-car-battery"
              primaryText="Servicios Históricos"
              secondaryText="Reemplazos activos y unidades fuera de servicio históricos."
              to="/ReemplazosFueraServicioHistorico"
              :disabled="!isButtonEnabled"
              :class="{ disabled: !isButtonEnabled }"
            />
            <!-- Botón Mantenciones. -->
            <Boton
              icon="fa-solid fa-wrench"
              primaryText="Mantenciones - Revisiones Históricas"
              secondaryText="Mantenciones pendientes y revisiones técnicas/gases pendientes históricas."
              to="/MantencionesyRevisionesPendientesHistorico"
              :disabled="!isButtonEnabled"
              :class="{ disabled: !isButtonEnabled }"
            />
            <!-- Botón CPA. -->
            <Boton
              icon="fa-solid fa-dollar-sign"
              primaryText="CPA Histórico"
              secondaryText="CPA pendientes históricos."
              to="/CpaHistorico"
              :disabled="!isButtonEnabled"
              :class="{ disabled: !isButtonEnabled }"
            />
          </div>
        </div>

        <!-- Mensaje para los casos en que aún no se ha seleccionado una fecha. -->
        <div class="right-column-historico">
          <div v-if="!selectedDate">
            <p>
              Esperando selección de fecha y/o de supervisor para visualizar las
              métricas.
            </p>
          </div>
          <!-- <div v-if="!selectedSup">
            <p>Esperando selección de supervisor para visualizar las métricas.</p>
          </div> -->

          <!-- Tablas de métricas -->
          <div v-if="!isLoading && showTables && hasData">
            <!-- Tabla métrica CPA. -->
            <div class="table-wrapper-historico">
              <Tabla
                tableTitle="% CPA menor a 3 meses"
                :subnum1="
                  metrica_cpa_porcentaje === 'no data'
                    ? 'no available information for today'
                    : metrica_cpa_porcentaje + '%'
                "
                :disabled="true"
                :enablePopup="true"
                modalTableTitle="% CPA menor a 3 meses"
                :modalSubtable1="'# CPA pendientes menores a 3 meses'"
                :modalSubtable2="'% CPA menor a 3 meses'"
                :modalSubnum1="metrica_cpa_num"
                :modalSubnum2="metrica_cpa_porcentaje + '%'"
                :modalNumColspan="7"
                :modalSubnumColspan="4"
                :modalDataColspan="4"
                :modalData="cpaInfo"
                :modalHeaders="[
                  'Cliente',
                  'Código CPA',
                  'PPU',
                  'CPA',
                  'Cargos',
                  'Fecha Apertura',
                  'Días Aperturado',
                ]"
                :modalRowFields="[
                  { key: 'CLIENTE', format: 'text' },
                  { key: 'CODIGO_CPA', format: 'text' },
                  { key: 'PPU', format: 'text' },
                  { key: 'CPA', format: 'text' },
                  { key: 'Cargos', format: 'text' },
                  { key: 'FECHA_APERTURA', format: 'date' },
                  { key: 'DIAS_APERTURADO', format: 'number' },
                ]"
                :modalExportDisabled="false"
                :modalEnableFilters="[true, true, true, true, true, true, true]"
              />
            </div>

            <!-- Tabla métrica mantenciones pendientes por KM. -->
            <div class="table-wrapper-historico">
              <Tabla
                tableTitle="% Mantenciones por KM pendientes"
                :subnum1="
                  metrica_mantenciones4d_porcentaje === 'no data'
                    ? 'no available information for today'
                    : metrica_mantenciones4d_porcentaje + '%'
                "
                :disabled="true"
                :enablePopup="true"
                modalTableTitle="% Mantenciones por KM pendientes"
                :modalSubtable1="'# Unidades con mantenciones por KM pendientes'"
                :modalSubtable2="'% Mantenciones por KM pendientes'"
                :modalSubnum1="metrica_mantenciones4d_num"
                :modalSubnum2="metrica_mantenciones4d_porcentaje + '%'"
                :modalNumColspan="9"
                :modalSubnumColspan="5"
                :modalDataColspan="5"
                :modalData="mantenciones4dInfo"
                :modalHeaders="[
                  'Cliente',
                  'PPU',
                  'CODIGO',
                  'Región',
                  'KM 4D',
                  'KM MP',
                  'KM Prox MP',
                  'Fecha MP',
                  'Exceso 4D',
                ]"
                :modalRowFields="[
                  { key: 'CLIENTE', format: 'text' },
                  { key: 'PPU', format: 'text' },
                  { key: 'Codigo', format: 'text' },
                  { key: 'REGION', format: 'text' },
                  { key: 'KM_4D', format: 'number' },
                  { key: 'KM_MP', format: 'number' },
                  { key: 'KM_PROX_MP', format: 'number' },
                  { key: 'FECHA_MP', format: 'date' },
                  { key: 'EXCESO_4D', format: 'number' },
                ]"
                :modalExportDisabled="false"
                :modalEnableFilters="[
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                ]"
              />
            </div>

            <!-- Tabla métrica mantenciones pendientes por día. -->
            <div class="table-wrapper-historico">
              <Tabla
                tableTitle="% Mantenciones por tiempo pendientes"
                :subnum1="
                  metrica_mantencionesest_porcentaje === 'no data'
                    ? 'no available information for today'
                    : metrica_mantencionesest_porcentaje + '%'
                "
                :disabled="true"
                :enablePopup="true"
                modalTableTitle="% Mantenciones por tiempo pendientes"
                :modalSubtable1="'# Unidades con mantenciones por día pendientes'"
                :modalSubtable2="'% Mantenciones por tiempo pendientes'"
                :modalSubnum1="metrica_mantencionesest_num"
                :modalSubnum2="metrica_mantencionesest_porcentaje + '%'"
                :modalNumColspan="10"
                :modalSubnumColspan="5"
                :modalDataColspan="5"
                :modalData="mantencionesestInfo"
                :modalHeaders="[
                  'Cliente',
                  'PPU',
                  'Codigo',
                  'Región',
                  'KM 4D',
                  'KM EST',
                  'KM MP',
                  'KM Prox MP',
                  'Fecha MP',
                  'Exceso EST',
                ]"
                :modalRowFields="[
                  { key: 'CLIENTE', format: 'text' },
                  { key: 'PPU', format: 'text' },
                  { key: 'CODIGO', format: 'text' },
                  { key: 'REGION', format: 'text' },
                  { key: 'KM_4D', format: 'number' },
                  { key: 'KMS_EST', format: 'number' },
                  { key: 'KM_MP', format: 'number' },
                  { key: 'KM_PROX_MP', format: 'number' },
                  { key: 'FECHA_MP', format: 'date' },
                  { key: 'EXCESO_EST', format: 'number' },
                ]"
                :modalExportDisabled="false"
                :modalEnableFilters="[
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                ]"
              />
            </div>

            <!-- Tabla métrica revisiones técnicas pendientes. -->
            <div class="table-wrapper-historico">
              <Tabla
                tableTitle="% Revisiones técnicas pendientes"
                :subnum1="
                  metrica_revisionestecnicas_porcentaje === 'no data'
                    ? 'no available information for today'
                    : metrica_revisionestecnicas_porcentaje + '%'
                "
                :disabled="true"
                :enablePopup="true"
                modalTableTitle="% Revisiones técnicas pendientes"
                :modalSubtable1="'# Unidades con revisiones técnicas pendientes'"
                :modalSubtable2="'% Revisiones técnicas pendientes'"
                :modalSubnum1="metrica_revisionestecnicas_num"
                :modalSubnum2="metrica_revisionestecnicas_porcentaje + '%'"
                :modalNumColspan="5"
                :modalSubnumColspan="3"
                :modalDataColspan="3"
                :modalData="revisionestecnicasInfo"
                :modalHeaders="[
                  'Cliente',
                  'PPU',
                  'Codigo',
                  'Ubicación',
                  'Rev. Técnica',
                ]"
                :modalRowFields="[
                  { key: 'CLIENTE', format: 'text' },
                  { key: 'PPU', format: 'text' },
                  { key: 'CODIGO', format: 'text' },
                  { key: 'REGION', format: 'text' },
                  { key: 'Rev_TECNICA', format: 'date' },
                ]"
                :modalExportDisabled="false"
                :modalEnableFilters="[true, true, true, true, true]"
              />
            </div>

            <!-- Tabla métrica revisiones gases pendientes. -->
            <div class="table-wrapper-historico">
              <Tabla
                tableTitle="% Revisiones gases pendientes"
                :subnum1="
                  metrica_revisionesgases_porcentaje === 'no data'
                    ? 'no available information for today'
                    : metrica_revisionesgases_porcentaje + '%'
                "
                :disabled="true"
                :enablePopup="true"
                modalTableTitle="% Revisiones gases pendientes"
                :modalSubtable1="'# Unidades con revisiones gases pendientes'"
                :modalSubtable2="'% Revisiones gases pendientes'"
                :modalSubnum1="metrica_revisionesgases_num"
                :modalSubnum2="metrica_revisionesgases_porcentaje + '%'"
                :modalNumColspan="5"
                :modalSubnumColspan="3"
                :modalDataColspan="3"
                :modalData="revisionesgasesInfo"
                :modalHeaders="[
                  'Cliente',
                  'PPU',
                  'Codigo',
                  'Ubicación',
                  'Rev. Gases',
                ]"
                :modalRowFields="[
                  { key: 'CLIENTE', format: 'text' },
                  { key: 'PPU', format: 'text' },
                  { key: 'CODIGO', format: 'text' },
                  { key: 'REGION', format: 'text' },
                  { key: 'Rev_GASES', format: 'date' },
                ]"
                :modalExportDisabled="false"
                :modalEnableFilters="[true, true, true, true, true]"
              />
            </div>

            <!-- Tabla métrica reemplazos. -->
            <div class="table-wrapper-historico">
              <Tabla
                tableTitle="Promedio días reemplazo cierre menor a 5 días"
                :subnum1="
                  metrica_reemplazos_porcentaje === 'no data'
                    ? 'no available information for today'
                    : metrica_reemplazos_porcentaje + ' Días'
                "
                :disabled="true"
                :enablePopup="true"
                modalTableTitle="Promedio días reemplazo cierre menor a 5 días"
                :modalSubtable1="'# Unidades promedio días de cierre de reemplazo menores a 5 días'"
                :modalSubtable2="'Promedio días cierre reemplazo menor a 5 días'"
                :modalSubnum1="metrica_reemplazos_num"
                :modalSubnum2="metrica_reemplazos_porcentaje"
                :modalNumColspan="10"
                :modalSubnumColspan="5"
                :modalDataColspan="5"
                :modalData="reemplazosInfo"
                :modalHeaders="[
                  'Cliente',
                  'PPU Reemplazo',
                  'Código Reemplazo',
                  'PPU Titular',
                  'Código Titular',
                  'Status Titular',
                  'Región',
                  'Fecha Reemplazo',
                  'Días Reemplazo',
                  'Días Cierre OT',
                ]"
                :modalRowFields="[
                  { key: 'Cliente', format: 'text' },
                  { key: 'PPU_REEMPLAZO', format: 'text' },
                  { key: 'CODIGO_REEMPLAZO', format: 'text' },
                  { key: 'PPU_ORIGINAL', format: 'text' },
                  { key: 'CODIGO_ORIGINAL', format: 'text' },
                  { key: 'STATUS_ORIGINAL', format: 'text' },
                  { key: 'REGION', format: 'text' },
                  { key: 'FECHA_REEMPLAZO', format: 'date' },
                  { key: 'DIAS_REEMPLAZO', format: 'number' },
                  { key: 'Dias_Cierre_OT', format: 'number' },
                ]"
                :modalExportDisabled="false"
                :modalEnableFilters="[
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                ]"
              />
            </div>

            <!-- Tabla métrica unidades nuevas. -->
            <div class="table-wrapper">
              <Tabla
                tableTitle="Promedio días LOP nuevo sin contrato"
                :subnum1="
                  metrica_unidadesnuevas_promedio === 'no data'
                    ? 'no available information for today'
                    : metrica_unidadesnuevas_promedio + ' Días'
                "
                :disabled="true"
                :enablePopup="true"
                modalTableTitle="Promedio días LOP nuevo sin contrato"
                :modalSubtable1="'# Unidades'"
                :modalSubtable2="'Promedio días'"
                :modalSubnum1="metrica_unidadesnuevas_num || 0"
                :modalSubnum2="metrica_unidadesnuevas_promedio || 0"
                :modalNumColspan="8"
                :modalSubnumColspan="4"
                :modalDataColspan="4"
                :modalData="unidadesnuevasInfo"
                :modalHeaders="[
                  'Cliente',
                  'PPU',
                  'Codigo',
                  'Region',
                  'Ubicación',
                  'Fecha Disponible',
                  'Fecha Alquilado',
                  'Días Disponible',
                ]"
                :modalRowFields="[
                  { key: 'CLIENTE', format: 'text' },
                  { key: 'Patente', format: 'text' },
                  { key: 'CODIGO', format: 'text' },
                  { key: 'Region', format: 'text' },
                  { key: 'Ubicacion', format: 'text' },
                  { key: 'FECHA_DISPONIBLE', format: 'date' },
                  { key: 'FECHA_ALQUILADO', format: 'date' },
                  { key: 'DIAS_DISPONIBLE', format: 'number' },
                ]"
                :modalExportDisabled="false"
                :modalEnableFilters="[
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                ]"
              />
            </div>

            <!-- Tabla métrica próximos vencimientos. -->
            <div class="table-wrapper">
              <Tabla
                tableTitle="% Próximos vencimientos de contratos"
                :subnum1="
                  metrica_proximosvencimientos_porcentaje === 'no data'
                    ? 'no available information for today'
                    : metrica_proximosvencimientos_porcentaje + '%'
                "
                :disabled="true"
                :enablePopup="true"
                modalTableTitle="% Próximos vencimientos de contratos"
                :modalSubtable1="'# Próximos vencimientos de contratos'"
                :modalSubtable2="'% Próximos vencimientos de contratos'"
                :modalSubnum1="metrica_proximosvencimientos_num || 0"
                :modalSubnum2="
                  metrica_proximosvencimientos_porcentaje + '%' || '0%'
                "
                :modalNumColspan="5"
                :modalSubnumColspan="3"
                :modalDataColspan="3"
                :modalData="proximosvencimientosInfo"
                :modalHeaders="[
                  'Cliente',
                  'PPU',
                  'Codigo',
                  'Fin Lease',
                  'Días Vencimiento Restantes',
                ]"
                :modalRowFields="[
                  { key: 'CLIENTE', format: 'text' },
                  { key: 'PPU', format: 'text' },
                  { key: 'CODIGO', format: 'text' },
                  { key: 'FIN_LEASE', format: 'date' },
                  { key: 'DIAS_VENCIMIENTO', format: 'number' },
                ]"
                :modalExportDisabled="false"
                :modalEnableFilters="[true, true, true, true, true]"
              />
            </div>
          </div>
          <div v-if="isLoading" class="loading-spinner">
            <div class="spinner-container">
              <i class="fa-solid fa-spinner fa-spin"></i>
              <p>Cargando métricas, por favor espere...</p>
            </div>
          </div>

          <!-- Mensaje para los casos en que no hay información almacenada en el día seleccionada. -->
          <div v-if="!isLoading && showTables && !hasData">
            <p>No se almacenaron métricas en la fecha seleccionada.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Se importan las librerías y componentes necesarios.
import { computed, ref, watch, onMounted } from "vue";
import Tabla from "../components/Tabla_metricas.vue";
import Boton from "../components/Boton.vue";
import SideMenu from "../components/SideMenu.vue";
import ButtonsMenu from "../components/Menu.vue";
import { useClientStore } from "../stores/clientStore.js";
import { useSupStore } from "../stores/supStore.js";
import { useDateStore } from "../stores/dateStore.js";
import useUserStore from "@/stores/userStore";

// Se importan las funciones necesarias para obtener los datos de las métricas de los supervisores.
import {
  getReporteMetricaSupervisorCPA,
  getReporteMetricaSupervisorMantenciones4D,
  getReporteMetricaSupervisorMantencionesEST,
  getReporteMetricaSupervisorRevisionesTecnicas,
  getReporteMetricaSupervisorRevisionesGases,
  getReporteMetricaSupervisorReemplazos,
  getReporteMetricaSupervisorUnidadesNuevas,
  getReporteMetricaSupervisorProximosVencimientos,
} from "../api/metricassupervisor.js";

// Se importan las funciones necesarias para obtener los datos de las métricas generales.
import {
  getReporteMetricaGeneralCPA,
  getReporteMetricaGeneralMantenciones4D,
  getReporteMetricaGeneralMantencionesEST,
  getReporteMetricaGeneralRevisionesTecnicas,
  getReporteMetricaGeneralRevisionesGases,
  getReporteMetricaGeneralReemplazos,
  getReporteMetricaGeneralUnidadesNuevas,
  getReporteMetricaGeneralProximosVencimientos,
} from "../api/metricasgeneral.js";

export default {
  name: "HistoricHomePage",
  components: {
    Boton,
    Tabla,
    SideMenu,
    ButtonsMenu,
  },
  setup() {
    // Se obtienen las tiendas.
    const userStore = useUserStore();
    const userRole = computed(() => userStore.user?.rol);
    const clientStore = useClientStore();
    const supStore = useSupStore();
    const dateStore = useDateStore();

    // Se establecen variables con base en la información obtenida de las tiendas.
    const selectedDate = ref(dateStore.selectedDate || null);
    const selectedClient = computed(() => clientStore.selectedClient);
    const selectedSup = computed(() => supStore.selectedSup);
    const showTables = ref(false);
    const isLoading = ref(false);

    // Se establecen variables reactivas para almacenar la información de las métricas.
    const cpaInfo = ref([]);
    const mantenciones4dInfo = ref([]);
    const mantencionesestInfo = ref([]);
    const revisionestecnicasInfo = ref([]);
    const revisionesgasesInfo = ref([]);
    const reemplazosInfo = ref([]);
    const unidadesnuevasInfo = ref([]);
    const proximosvencimientosInfo = ref([]);
    const metrica_cpa_porcentaje = ref(null);
    const metrica_mantenciones4d_porcentaje = ref(null);
    const metrica_mantencionesest_porcentaje = ref(null);
    const metrica_revisionestecnicas_porcentaje = ref(null);
    const metrica_revisionesgases_porcentaje = ref(null);
    const metrica_reemplazos_porcentaje = ref(null);
    const metrica_unidadesnuevas_promedio = ref(null);
    const metrica_proximosvencimientos_porcentaje = ref(null);
    const metrica_cpa_num = ref(null);
    const metrica_mantenciones4d_num = ref(null);
    const metrica_mantencionesest_num = ref(null);
    const metrica_revisionestecnicas_num = ref(null);
    const metrica_revisionesgases_num = ref(null);
    const metrica_reemplazos_num = ref(null);
    const metrica_unidadesnuevas_num = ref(null);
    const metrica_proximosvencimientos_num = ref(null);

    // Se establece la fecha actual.
    const today = new Date().toISOString().split("T")[0];

    // Se define el valor de Sup_Cod_Cartera con base en el rol del usuario.
    const Sup_Cod_Cartera = computed(() => {
      if (userRole.value === "manager") {
        return userStore.user.id || null;
      } else if (userRole.value === "admin") {
        return selectedSup.value?.unikey_SU || null;
      }
      return null;
    });

    // Función para resetear las variables.
    const resetMetrics = () => {
      cpaInfo.value = [];
      mantenciones4dInfo.value = [];
      mantencionesestInfo.value = [];
      revisionestecnicasInfo.value = [];
      revisionesgasesInfo.value = [];
      reemplazosInfo.value = [];
      unidadesnuevasInfo.value = [];
      proximosvencimientosInfo.value = [];
      metrica_cpa_porcentaje.value = null;
      metrica_mantenciones4d_porcentaje.value = null;
      metrica_mantencionesest_porcentaje.value = null;
      metrica_revisionestecnicas_porcentaje.value = null;
      metrica_revisionesgases_porcentaje.value = null;
      metrica_reemplazos_porcentaje.value = null;
      metrica_unidadesnuevas_promedio.value = null;
      metrica_proximosvencimientos_porcentaje.value = null;
      metrica_cpa_num.value = null;
      metrica_mantenciones4d_num.value = null;
      metrica_mantencionesest_num.value = null;
      metrica_revisionestecnicas_num.value = null;
      metrica_revisionesgases_num.value = null;
      metrica_reemplazos_num.value = null;
      metrica_unidadesnuevas_num.value = null;
      metrica_proximosvencimientos_num.value = null;
    };

    // Se define una variable que indicará si hay información de cada una las métricas.
    const hasData = computed(() => {
      return (
        (cpaInfo.value.length > 0 && metrica_cpa_porcentaje.value !== null) ||
        (mantenciones4dInfo.value.length > 0 &&
          metrica_mantenciones4d_porcentaje.value !== null) ||
        (mantencionesestInfo.value.length > 0 &&
          metrica_mantencionesest_porcentaje.value !== null) ||
        (revisionestecnicasInfo.value.length > 0 &&
          metrica_revisionestecnicas_porcentaje.value !== null) ||
        (revisionesgasesInfo.value.length > 0 &&
          metrica_revisionesgases_porcentaje.value !== null) ||
        (reemplazosInfo.value.length > 0 &&
          metrica_reemplazos_porcentaje.value !== null) ||
        (unidadesnuevasInfo.value.length > 0 &&
          metrica_unidadesnuevas_promedio.value !== null) ||
        (proximosvencimientosInfo.value.length > 0 &&
          metrica_proximosvencimientos_porcentaje.value !== null)
      );
    });

    // Función que muestra la información en cada cambio de fecha seleccionada.
    const onDateChange = async () => {
      if (!selectedDate.value) {
        showTables.value = false;
        return;
      }

      // Se actualiza la fecha seleccionada en la tienda.
      dateStore.setSelectedDate(selectedDate.value);

      // Se resetean las métricas.
      resetMetrics();
      isLoading.value = true;

      // Se establece en 100 el valor de isGeneral.
      const isGeneral = Sup_Cod_Cartera.value === 100;

      try {
        // Si isGeneral tiene un valor definido, entonces cargará las métricas generales, en caso contrario se cargan las métricas de supervisor.
        if (isGeneral) {
          await loadGeneralCPAData();
          await loadGeneralMantenciones4DData();
          await loadGeneralMantencionesESTData();
          await loadGeneralRevisionesTecnicasData();
          await loadGeneralRevisionesGasesData();
          await loadGeneralReemplazosData();
          await loadGeneralUnidadesNuevasData();
          await loadGeneralProximosVencimientosData();
        } else {
          await loadSupervisorCPAData();
          await loadSupervisorMantenciones4DData();
          await loadSupervisorMantencionesESTData();
          await loadSupervisorRevisionesTecnicasData();
          await loadSupervisorRevisionesGasesData();
          await loadSupervisorReemplazosData();
          await loadSupervisorUnidadesNuevasData();
          await loadSupervisorProximosVencimientosData();
        }
        showTables.value = true;
      } catch (error) {
        console.error("Error al cargar los datos:", error.message);
      } finally {
        isLoading.value = false;
      }
    };

    // Función para obtener la información de la métrica del supervisor de CPA.
    const loadSupervisorCPAData = async () => {
      try {
        const response = await getReporteMetricaSupervisorCPA(
          selectedDate.value,
          "CPA",
          Sup_Cod_Cartera.value
        );

        const reporte = response?.datametricacpasupervisor;

        if (reporte) {
          metrica_cpa_porcentaje.value = reporte.PORCENTAJE_CPA;
          metrica_cpa_num.value = reporte.CPA_NUM;

          cpaInfo.value = reporte.datametricacpasupervisor.map((item) => ({
            ...item,
            CPA: formatCurrency(item.CPA),
            Cargos: formatCurrency(item.Cargos),
            FECHA_APERTURA: formatDate(item.FECHA_APERTURA),
            DIAS_APERTURADO: formatNumber(item.DIAS_APERTURADO),
          }));
        } else {
          metrica_cpa_porcentaje.value = "no data";
          metrica_cpa_num.value = "no data";
        }
      } catch (error) {
        console.error(
          "Error al cargar los datos de CPA (supervisor):",
          error.message
        );
      }
    };

    // Función para obtener la información de la métrica del supervisor de Mantenciones 4D.
    const loadSupervisorMantenciones4DData = async () => {
      try {
        const response = await getReporteMetricaSupervisorMantenciones4D(
          selectedDate.value,
          "Mantenciones4D",
          Sup_Cod_Cartera.value
        );
        const reporte = response?.datametricamantenciones4dsupervisor;
        if (reporte) {
          metrica_mantenciones4d_porcentaje.value = reporte.PORCENTAJE_4D;
          metrica_mantenciones4d_num.value = reporte.UNIDADES_PENDIENTES_4D;
          mantenciones4dInfo.value =
            reporte.datametricamantenciones4dsupervisor.map((item) => ({
              ...item,
              KM_4D: formatNumber(item.KM_4D),
              KM_MP: formatNumber(item.KM_MP),
              KM_PROX_MP: formatNumber(item.KM_PROX_MP),
              FECHA_MP: formatDate(item.FECHA_MP),
              EXCESO_4D: formatNumber(item.EXCESO_4D),
            }));
        } else {
          metrica_mantenciones4d_porcentaje.value = "no data";
          metrica_mantenciones4d_num.value = "no data";
        }
      } catch (error) {
        console.error(
          "Error al cargar los datos de Mantenciones 4D (supervisor):",
          error.message
        );
      }
    };

    // Función para obtener la información de la métrica del supervisor de Mantenciones EST.
    const loadSupervisorMantencionesESTData = async () => {
      try {
        const response = await getReporteMetricaSupervisorMantencionesEST(
          selectedDate.value,
          "MantencionesEST",
          Sup_Cod_Cartera.value
        );
        const reporte = response?.datametricamantencionesestsupervisor;
        if (reporte) {
          metrica_mantencionesest_porcentaje.value = reporte.PORCENTAJE_EST;
          metrica_mantencionesest_num.value = reporte.UNIDADES_PENDIENTES_EST;
          mantencionesestInfo.value =
            reporte.datametricamantencionesestsupervisor.map((item) => ({
              ...item,
              KM_4D: formatNumber(item.KM_4D),
              KMS_EST: formatNumber(item.KMS_EST),
              KM_MP: formatNumber(item.KM_MP),
              KM_PROX_MP: formatNumber(item.KM_PROX_MP),
              FECHA_MP: formatDate(item.FECHA_MP),
              EXCESO_EST: formatNumber(item.EXCESO_EST),
            }));
        } else {
          metrica_mantencionesest_porcentaje.value = "no data";
          metrica_mantencionesest_num.value = "no data";
        }
      } catch (error) {
        console.error(
          "Error al cargar los datos de Mantenciones EST (supervisor):",
          error.message
        );
      }
    };

    // Función para obtener la información de la métrica del supervisor de Revisiones Técnicas.
    const loadSupervisorRevisionesTecnicasData = async () => {
      try {
        const response = await getReporteMetricaSupervisorRevisionesTecnicas(
          selectedDate.value,
          "RevisionesTecnicas",
          Sup_Cod_Cartera.value
        );
        const reporte = response?.datametricarevisionestecnicassupervisor;
        if (reporte) {
          metrica_revisionestecnicas_porcentaje.value =
            reporte.PORCENTAJE_REVISIONES_Tecnicas_SUPERVISOR;
          metrica_revisionestecnicas_num.value =
            reporte.REVISIONES_Tecnicas_SUPERVISOR_Num;
          revisionestecnicasInfo.value =
            reporte.datametricarevisionestecnicassupervisor.map((item) => ({
              ...item,
              Rev_TECNICA: formatDate(item.Rev_TECNICA),
            }));
        } else {
          metrica_revisionestecnicas_porcentaje.value = "no data";
          metrica_revisionestecnicas_num.value = "no data";
        }
      } catch (error) {
        console.error(
          "Error al cargar los datos de Revisiones Técnicas (supervisor):",
          error.message
        );
      }
    };

    //  Función para obtener la información de la métrica del supervisor de Revisiones Gases.
    const loadSupervisorRevisionesGasesData = async () => {
      try {
        const response = await getReporteMetricaSupervisorRevisionesGases(
          selectedDate.value,
          "RevisionesGases",
          Sup_Cod_Cartera.value
        );
        const reporte = response?.datametricarevisionesgasessupervisor;
        if (reporte) {
          metrica_revisionesgases_porcentaje.value =
            reporte.PORCENTAJE_REVISIONES_Gases_SUPERVISOR;
          metrica_revisionesgases_num.value =
            reporte.REVISIONES_Gases_SUPERVISOR_Num;
          revisionesgasesInfo.value =
            reporte.datametricarevisionesgasessupervisor.map((item) => ({
              ...item,
              Rev_GASES: formatDate(item.Rev_GASES),
            }));
        } else {
          metrica_revisionesgases_porcentaje.value =
            reporte.PORCENTAJE_CPA || "no data";
          metrica_revisionesgases_num.value = reporte.CPA_NUM || "no data";
        }
      } catch (error) {
        console.error(
          "Error al cargar los datos de Revisiones Gases (supervisor):",
          error.message
        );
      }
    };

    // Función para obtener la información de la métrica del supervisor de Reemplazos.
    const loadSupervisorReemplazosData = async () => {
      try {
        const response = await getReporteMetricaSupervisorReemplazos(
          selectedDate.value,
          "Reemplazos",
          Sup_Cod_Cartera.value
        );
        const reporte = response?.datametricareemplazossupervisor;
        if (reporte) {
          metrica_reemplazos_porcentaje.value =
            reporte.PROMEDIO_DIAS_REEMPLAZO_SUPERVISOR;
          metrica_reemplazos_num.value = reporte.Reemplazos_cierre_menor_5_dias;
          reemplazosInfo.value = reporte.datametricareemplazossupervisor.map(
            (item) => ({
              ...item,
              FECHA_REEMPLAZO: formatDate(item.FECHA_REEMPLAZO),
              DIAS_REEMPLAZO: formatNumber(item.DIAS_REEMPLAZO),
              Dias_Cierre_OT: formatNumber(item.Dias_Cierre_OT),
            })
          );
        } else {
          metrica_reemplazos_porcentaje.value = "no data";
          metrica_reemplazos_num.value = "no data";
        }
      } catch (error) {
        console.error(
          "Error al cargar los datos de Reemplazos (supervisor):",
          error.message
        );
      }
    };

    // Función para obtener la información de la métrica del supervisor de Unidades Nuevas.
    const loadSupervisorUnidadesNuevasData = async () => {
      try {
        const response = await getReporteMetricaSupervisorUnidadesNuevas(
          selectedDate.value,
          "UnidadesNuevas",
          Sup_Cod_Cartera.value
        );

        const reporte = response?.datametricaunidadesnuevassupervisor;
        if (reporte) {
          metrica_unidadesnuevas_promedio.value =
            reporte.PROMEDIO_DIAS_DISPONIBLE;
          metrica_unidadesnuevas_num.value = reporte.UNIDADES_Nuevas;
          unidadesnuevasInfo.value =
            reporte.datametricaunidadesnuevassupervisor.map((item) => ({
              ...item,
              FECHA_DISPONIBLE: formatDate(item.FECHA_DISPONIBLE),
              FECHA_ALQUILADO: formatDate(item.FECHA_ALQUILADO),
              DIAS_DISPONIBLE: formatNumber(item.DIAS_DISPONIBLE),
            }));
        } else {
          metrica_unidadesnuevas_promedio.value = "no data";
          metrica_unidadesnuevas_num.value = "no data";
        }
      } catch (error) {
        console.error(
          "Error al cargar los datos de Unidades Nuevas (supervisor):",
          error.message
        );
      }
    };

    // Función para obtener la información de la métrica del supervisor de Próximos Vencimientos.
    const loadSupervisorProximosVencimientosData = async () => {
      try {
        const response = await getReporteMetricaSupervisorProximosVencimientos(
          selectedDate.value,
          "ProximosVencimientos",
          Sup_Cod_Cartera.value
        );
        const reporte = response?.datametricaproximosvencimientossupervisor;
        if (reporte) {
          metrica_proximosvencimientos_porcentaje.value =
            reporte.PORCENTAJE_PROXIMOS_VENCIMIENTOS;
          metrica_proximosvencimientos_num.value =
            reporte.PROXIMOS_VENCIMIENTOS;
          proximosvencimientosInfo.value =
            reporte.datametricaproximosvencimientossupervisor.map((item) => ({
              ...item,
              FIN_LEASE: formatDate(item.FIN_LEASE),
              DIAS_VENCIMIENTO: formatNumber(item.DIAS_VENCIMIENTO),
            }));
        } else {
          metrica_proximosvencimientos_porcentaje.value = "no data";
          metrica_proximosvencimientos_num.value = "no data";
        }
        showTables.value = true;
      } catch (error) {
        console.error(
          "Error al cargar los datos de Próximos Vencimientos (supervisor):",
          error.message
        );
      }
    };

    // Función para obtener la información de la métrica general de CPA.
    const loadGeneralCPAData = async () => {
      try {
        const response = await getReporteMetricaGeneralCPA(
          selectedDate.value,
          "CPA",
          Sup_Cod_Cartera.value
        );
        const reporte = response?.datametricacpageneral;
        if (reporte) {
          metrica_cpa_porcentaje.value = reporte.PORCENTAJE_CPA;
          metrica_cpa_num.value = reporte.CPA_NUM;
          cpaInfo.value = reporte.datametricacpageneral.map((item) => ({
            ...item,
            CPA: formatCurrency(item.CPA),
            Cargos: formatCurrency(item.Cargos),
            FECHA_APERTURA: formatDate(item.FECHA_APERTURA),
            DIAS_APERTURADO: formatNumber(item.DIAS_APERTURADO),
          }));
        } else {
          metrica_cpa_porcentaje.value = "no data";
          metrica_cpa_num.value = "no data";
        }
      } catch (error) {
        console.error(
          "Error al cargar los datos de CPA (general):",
          error.message
        );
      }
    };

    // Función para obtener la información de la métrica general de Mantenciones 4D.
    const loadGeneralMantenciones4DData = async () => {
      try {
        const response = await getReporteMetricaGeneralMantenciones4D(
          selectedDate.value,
          "Mantenciones4D",
          Sup_Cod_Cartera.value
        );
        const reporte = response?.datametricamantenciones4dgeneral;
        if (reporte) {
          metrica_mantenciones4d_porcentaje.value = reporte.PORCENTAJE_4D;
          metrica_mantenciones4d_num.value = reporte.UNIDADES_PENDIENTES_4D;
          mantenciones4dInfo.value =
            reporte.datametricamantenciones4dgeneral.map((item) => ({
              ...item,
              KM_4D: formatNumber(item.KM_4D),
              KM_MP: formatNumber(item.KM_MP),
              KM_PROX_MP: formatNumber(item.KM_PROX_MP),
              FECHA_MP: formatDate(item.FECHA_MP),
              EXCESO_4D: formatNumber(item.EXCESO_4D),
            }));
        } else {
          metrica_mantenciones4d_porcentaje.value = "no data";
          metrica_mantenciones4d_num.value = "no data";
        }
      } catch (error) {
        console.error(
          "Error al cargar los datos de Mantenciones 4D (general):",
          error.message
        );
      }
    };

    // Función para obtener la información de la métrica general de Mantenciones EST.
    const loadGeneralMantencionesESTData = async () => {
      try {
        const response = await getReporteMetricaGeneralMantencionesEST(
          selectedDate.value,
          "MantencionesEST",
          Sup_Cod_Cartera.value
        );
        const reporte = response?.datametricamantencionesestgeneral;
        if (reporte) {
          metrica_mantencionesest_porcentaje.value = reporte.PORCENTAJE_EST;
          metrica_mantencionesest_num.value = reporte.UNIDADES_PENDIENTES_EST;
          mantencionesestInfo.value =
            reporte.datametricamantencionesestgeneral.map((item) => ({
              ...item,
              KM_4D: formatNumber(item.KM_4D),
              KMS_EST: formatNumber(item.KMS_EST),
              KM_MP: formatNumber(item.KM_MP),
              KM_PROX_MP: formatNumber(item.KM_PROX_MP),
              FECHA_MP: formatDate(item.FECHA_MP),
              EXCESO_EST: formatNumber(item.EXCESO_EST),
            }));
        } else {
          metrica_mantencionesest_porcentaje.value = "no data";
          metrica_mantencionesest_num.value = "no data";
        }
      } catch (error) {
        console.error(
          "Error al cargar los datos de Mantenciones EST (general):",
          error.message
        );
      }
    };

    // Función para obtener la información de la métrica general de Revisiones Técnicas.
    const loadGeneralRevisionesTecnicasData = async () => {
      try {
        const response = await getReporteMetricaGeneralRevisionesTecnicas(
          selectedDate.value,
          "RevisionesTecnicas",
          Sup_Cod_Cartera.value
        );
        const reporte = response?.datametricarevisionestecnicasgeneral;
        if (reporte) {
          metrica_revisionestecnicas_porcentaje.value =
            reporte.PORCENTAJE_REVISIONES_Tecnicas_GENERAL;
          metrica_revisionestecnicas_num.value =
            reporte.REVISIONES_Tecnicas_GENERAL_Num;
          revisionestecnicasInfo.value =
            reporte.datametricarevisionestecnicasgeneral.map((item) => ({
              ...item,
              Rev_TECNICA: formatDate(item.Rev_TECNICA),
            }));
        } else {
          metrica_revisionestecnicas_porcentaje.value = "no data";
          metrica_revisionestecnicas_num.value = "no data";
        }
      } catch (error) {
        console.error(
          "Error al cargar los datos de Revisiones Técnicas (general):",
          error.message
        );
      }
    };

    // Función para obtener la información de la métrica general de Revisiones Gases.
    const loadGeneralRevisionesGasesData = async () => {
      try {
        const response = await getReporteMetricaGeneralRevisionesGases(
          selectedDate.value,
          "RevisionesGases",
          Sup_Cod_Cartera.value
        );
        const reporte = response?.datametricarevisionesgasesgeneral;
        if (reporte) {
          metrica_revisionesgases_porcentaje.value =
            reporte.PORCENTAJE_REVISIONES_Gases_GENERAL;
          metrica_revisionesgases_num.value =
            reporte.REVISIONES_Gases_GENERAL_Num;
          revisionesgasesInfo.value =
            reporte.datametricarevisionesgasesgeneral.map((item) => ({
              ...item,
              Rev_GASES: formatDate(item.Rev_GASES),
            }));
        } else {
          metrica_revisionesgases_porcentaje.value = "no data";
          metrica_revisionesgases_num.value = "no data";
        }
      } catch (error) {
        console.error(
          "Error al cargar los datos de Revisiones Gases (general):",
          error.message
        );
      }
    };

    // Función para obtener la información de la métrica general de Reemplazos.
    const loadGeneralReemplazosData = async () => {
      try {
        const response = await getReporteMetricaGeneralReemplazos(
          selectedDate.value,
          "Reemplazos",
          Sup_Cod_Cartera.value
        );

        const reporte = response?.datametricareemplazosgeneral;
        if (reporte) {
          metrica_reemplazos_porcentaje.value =
            reporte.PROMEDIO_DIAS_REEMPLAZO_General;
          metrica_reemplazos_num.value = reporte.Reemplazos_cierre_menor_5_dias;
          reemplazosInfo.value = reporte.datametricareemplazosgeneral.map(
            (item) => ({
              ...item,
              FECHA_REEMPLAZO: formatDate(item.FECHA_REEMPLAZO),
              DIAS_REEMPLAZO: formatNumber(item.DIAS_REEMPLAZO),
              Dias_Cierre_OT: formatNumber(item.Dias_Cierre_OT),
            })
          );
        } else {
          metrica_reemplazos_porcentaje.value = "no data";
          metrica_reemplazos_num.value = "no data";
        }
      } catch (error) {
        console.error(
          "Error al cargar los datos de Reemplazos (general):",
          error.message
        );
      }
    };

    // Función para obtener la información de la métrica general de Unidades Nuevas.
    const loadGeneralUnidadesNuevasData = async () => {
      try {
        const response = await getReporteMetricaGeneralUnidadesNuevas(
          selectedDate.value,
          "UnidadesNuevas",
          Sup_Cod_Cartera.value
        );

        const reporte = response?.datametricaunidadesnuevasgeneral;
        if (reporte) {
          metrica_unidadesnuevas_promedio.value =
            reporte.PROMEDIO_DIAS_DISPONIBLE;
          metrica_unidadesnuevas_num.value = reporte.UNIDADES_Nuevas;
          unidadesnuevasInfo.value =
            reporte.datametricaunidadesnuevasgeneral.map((item) => ({
              ...item,
              FECHA_DISPONIBLE: formatDate(item.FECHA_DISPONIBLE),
              FECHA_ALQUILADO: formatDate(item.FECHA_ALQUILADO),
              DIAS_DISPONIBLE: formatNumber(item.DIAS_DISPONIBLE),
            }));
        } else {
          metrica_unidadesnuevas_promedio.value = "no data";
          metrica_unidadesnuevas_num.value = "no data";
        }
      } catch (error) {
        console.error(
          "Error al cargar los datos de Unidades Nuevas (general):",
          error.message
        );
      }
    };

    // Función para obtener la información de la métrica general de Próximos Vencimientos.
    const loadGeneralProximosVencimientosData = async () => {
      try {
        const response = await getReporteMetricaGeneralProximosVencimientos(
          selectedDate.value,
          "ProximosVencimientos",
          Sup_Cod_Cartera.value
        );
        const reporte = response?.datametricaproximosvencimientosgeneral;
        if (reporte) {
          metrica_proximosvencimientos_porcentaje.value =
            reporte.PORCENTAJE_PROXIMOS_VENCIMIENTOS;
          metrica_proximosvencimientos_num.value =
            reporte.PROXIMOS_VENCIMIENTOS;
          proximosvencimientosInfo.value =
            reporte.datametricaproximosvencimientosgeneral.map((item) => ({
              ...item,
              FIN_LEASE: formatDate(item.FIN_LEASE),
              DIAS_VENCIMIENTO: formatNumber(item.DIAS_VENCIMIENTO),
            }));
        } else {
          metrica_proximosvencimientos_porcentaje.value = "no data";
          metrica_proximosvencimientos_num.value = "no data";
        }
        showTables.value = true;
      } catch (error) {
        console.error(
          "Error al cargar los datos de Próximos Vencimientos (general):",
          error.message
        );
      }
    };

    // Se ejecuta la función onDateChange al montar el componente.
    onMounted(() => {
      if (selectedDate.value) {
        onDateChange();
      }
    });

    // Funciones para dar formato a los números.
    const formatNumber = (value) => {
      if (
        value === "-" ||
        value === "N/A" ||
        value === null ||
        value === "NULL" ||
        value === "null"
      ) {
        return "0";
      }
      const truncatedValue = Math.trunc(value * 100) / 100;
      return new Intl.NumberFormat().format(truncatedValue);
    };

    // Función para dar formato a las fechas.
    const formatDate = (value) => {
      if (!value) return "N/A";
      return new Date(value).toLocaleDateString("es-CL");
    };

    // Función para dar formato a los valores monetarios.
    const formatCurrency = (value) => {
      if (value === "Sin costo asociado" || value === "Sin cargo asociado") {
        return value;
      }
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }).format(value);
    };

    // Se observa el cambio de fecha seleccionada y se ejecuta la función onDateChange.
    watch(selectedSup, async (newSup) => {
      if (newSup && userRole.value === "admin") {
        onDateChange();
      }
    });

    // En caso de que exista un cliente y un supervisor seleccionado, la variable isButtonEnabled entregara true.
    const isButtonEnabled = computed(
      () => selectedClient.value && selectedSup.value && selectedDate.value
    );

    // Se retornan las variables y funciones necesarias para el componente.
    return {
      selectedDate,
      onDateChange,
      showTables,
      cpaInfo,
      mantenciones4dInfo,
      mantencionesestInfo,
      revisionestecnicasInfo,
      revisionesgasesInfo,
      reemplazosInfo,
      unidadesnuevasInfo,
      proximosvencimientosInfo,
      metrica_cpa_porcentaje,
      metrica_mantenciones4d_porcentaje,
      metrica_mantencionesest_porcentaje,
      metrica_revisionestecnicas_porcentaje,
      metrica_revisionesgases_porcentaje,
      metrica_reemplazos_porcentaje,
      metrica_unidadesnuevas_promedio,
      metrica_proximosvencimientos_porcentaje,
      metrica_cpa_num,
      metrica_mantenciones4d_num,
      metrica_mantencionesest_num,
      metrica_revisionestecnicas_num,
      metrica_revisionesgases_num,
      metrica_reemplazos_num,
      metrica_unidadesnuevas_num,
      metrica_proximosvencimientos_num,
      isButtonEnabled,
      selectedClient,
      selectedSup,
      today,
      isLoading,
      hasData,
    };
  },
};
</script>

<!-- Se importa el css necesario para la estética correspondiente de la vista. -->
<style scoped>
@import "../css/home_historico.css";
@import "../css/spinner.css";
</style>
