<template>
  <!-- Se importan ambas navbars, puesto que se utilizarán en toda la web. -->
  <NavbarTop />
  <NavbarBottom />
  <!-- Se importa router-view para poder hacer las redirecciones a las distintas vistas. -->
  <router-view />
</template>

<script>
// Se importa el archivo css
import "./css/app.css";
import NavbarTop from "./components/Nabvar_Top.vue";
import NavbarBottom from "./components/Nabvar_Bottom.vue";
import useUserStore from "./stores/userStore";
import { onMounted } from "vue";

export default {
  components: {
    NavbarTop,
    NavbarBottom,
  },
  setup() {
    const userStore = useUserStore();

    onMounted(() => {
      userStore.loadUserFromStorage();
    });

    return {
      userStore,
    };
  },
};
</script>
