<template>
  <nav class="navbar-top">
    <!-- Dropdown de supervisores, visible solo para el rol "admin" y cuando no se está en la vista del login. -->
    <div class="dropdown-container" v-if="showNavbarItems && isAdmin">
      <div
        v-if="
          isCPAPage ||
          isFlotaPreLOPUniNuevasPage ||
          isMantencionesRevisionesPage ||
          isReemplazosFueraServicioPage
        "
        class="supervisor-selected"
      >
        {{ selectedSup ? selectedSup.name_SU : "Supervisor" }}
      </div>
      <div v-else>
        <!-- Se crea el dropdown que mostrará los supervisores activos actualmente. -->
        <button class="dropdown-button" @click="toggleDropdown">
          {{ selectedSup ? selectedSup.name_SU : "Supervisor" }}
          <i class="fa-solid fa-caret-down"></i>
        </button>
        <div class="dropdown-content" v-if="dropdownVisible">
          <a
            v-for="supervisor in supervisors"
            :key="supervisor.unikey_SU"
            href="#"
            @click="selectItem(supervisor)"
            :class="{
              selected:
                selectedSup && selectedSup.unikey_SU === supervisor.unikey_SU,
            }"
          >
            {{ supervisor.name_SU }}
          </a>
        </div>
      </div>
    </div>

    <!-- Botón para cerrar sesión, al ser presionado redirige a la vista login. -->
    <button class="logout-button" v-if="showNavbarItems" @click="logout">
      Cerrar Sesión
    </button>
  </nav>
</template>

<script>
import { ref, computed, watch, onMounted, onBeforeUnmount } from "vue";
import { useSupStore } from "../stores/supStore.js";
import useUserStore from "../stores/userStore.js";
import { useRouter, useRoute } from "vue-router";

export default {
  setup() {
    const dropdownVisible = ref(false);
    const route = useRoute();
    const router = useRouter();
    const supStore = useSupStore();
    const userStore = useUserStore();

    // Computed properties para ver el usuario y rol
    const user = computed(() => userStore.user);
    const selectedSup = computed(() => supStore.selectedSup);
    const supervisors = computed(() => supStore.supervisors);

    const showNavbarItems = computed(() => route.name !== "Login"); // Mostrar la navbar si no estamos en el login
    const isAdmin = computed(() => user.value?.rol === "admin");

    // Función para mostrar/ocultar el dropdown
    const toggleDropdown = () => {
      dropdownVisible.value = !dropdownVisible.value;
    };

    // Seleccionar un supervisor en el dropdown
    const selectItem = (supervisor) => {
      supStore.setSelectedSup(supervisor);
      dropdownVisible.value = false;
    };

    // Función de logout
    const logout = () => {
      userStore.logout();
      router.push("/Login");
    };

    // Watcher para reaccionar a la autenticación del usuario
    watch(
      () => userStore.isAuthenticated,
      async (isAuthenticated) => {
        if (isAuthenticated && isAdmin.value) {
          await supStore.loadSupervisors();
        }
      },
      { immediate: true }
    );

    onMounted(() => {
      document.addEventListener("click", handleClickOutside);
      document.addEventListener("keydown", handleKeyDown);
    });

    onBeforeUnmount(() => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    });

    // Funciones para manejar clics fuera del dropdown
    const handleClickOutside = (event) => {
      const dropdown = document.querySelector(".dropdown-container");
      if (dropdown && !dropdown.contains(event.target)) {
        dropdownVisible.value = false;
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        dropdownVisible.value = false;
      }
    };

    // Verifaciones de la vista actual.
    const isLoginPage = computed(() => route.name === "Login");
    const isCPAPage = computed(() => route.name === "Cpa");
    const isFlotaPreLOPUniNuevasPage = computed(
      () => route.name === "FlotaUnidadesNuevasPreLOP"
    );
    const isMantencionesRevisionesPage = computed(
      () => route.name === "MantencionesyRevisionesPendientes"
    );
    const isReemplazosFueraServicioPage = computed(
      () => route.name === "ReemplazosFueraServicio"
    );

    return {
      dropdownVisible,
      selectedSup,
      supervisors,
      toggleDropdown,
      selectItem,
      showNavbarItems,
      isAdmin,
      logout,
      isLoginPage,
      isCPAPage,
      isFlotaPreLOPUniNuevasPage,
      isMantencionesRevisionesPage,
      isReemplazosFueraServicioPage,
    };
  },
};
</script>

<style scoped>
@import "../css/navbartop.css";
</style>
