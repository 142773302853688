// Se importa lo necesario para la correcta llamada a la base de datos (se importa api).
import { api } from "../services/api.js";

// Función para obtener el id y el nombre de todos los supervisores LOP activos.
export async function getReporteSupervisores(fecha) {
  try {
    const response = await api.get(`/reportes/supervisores?fecha='${fecha}' `);
    return response.data;
  } catch (error) {
    console.error(
      "Error al obtener el reporte de los supervisores LOP activos actualmente:",
      error
    );
    throw error;
  }
}
